import React from 'react';
import './DigitalClock.css'

const DigitalClock = ({ days, hours, minutes, seconds }) => {
  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  return (
    <div className="clock-container">

      
      <div className="clock-digit">
        {formatTime(hours)}
        <div className="clock-label">Hours</div>
      </div>
      <div className="clock-separator">:</div>
      <div className="clock-digit">
        {formatTime(minutes)}
        <div className="clock-label">Minutes</div>
      </div>
      <div className="clock-separator">:</div>
      <div className="clock-digit">
        {formatTime(seconds)}
        <div className="clock-label">Seconds</div>
      </div>
    </div>
  );
};

export default DigitalClock;

/*
      <div className="clock-digit">
        {formatTime(days)}
        <div className="clock-label">Days</div>
      </div>
      <div className="clock-separator">:</div>
*/