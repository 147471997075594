export const ADD_BARS = "ADD_BARS";
export const ADD_ORDER = "ADD_ORDER";
export const ADD_PREDICTION = "ADD_PREDICTION";
export const ADD_ACTIVITY = "ADD_ACTIVITY";
export const CLEAR_BARS = "CLEAR_BARS";
export const CLEAR_ORDER = "CLEAR_ORDER";
export const CLEAR_PREDICTION = "CLEAR_PREDICTION";
export const CLEAR_ACTIVITY = "CLEAR_ACTIVITY";

export const addBars = (payload) => ({
  type: ADD_BARS,
  payload,
});

export const addOrder = (payload) => ({
  type: ADD_ORDER,
  payload,
});

export const addPrediction = (payload) => ({
  type: ADD_PREDICTION,
  payload,
});

export const addActivity = (payload) => ({
  type: ADD_ACTIVITY,
  payload,
});

export const clearBars = () => ({
  type: CLEAR_BARS,
});

export const clearOrder = () => ({
  type: CLEAR_ORDER,
});

export const clearPrediction = () => ({
  type: CLEAR_PREDICTION,
});

export const clearActivity = () => ({
  type: CLEAR_ACTIVITY,
});
