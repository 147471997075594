import { useSelector } from "react-redux";
import { Container, Row, Col, Table, Badge } from "react-bootstrap";
import getTrades from "../service/TradeGroup";
import { useState, useEffect } from "react";

const dateToString = (d) => `${d.getHours()}:${d.getMinutes()}`;

//const array = [{/*item1*/}, {/*item2*/}, {/*item3*/}];
//const limitOrders = [{/*order1*/}, {/*order2*/}, {/*order3*/}];
//const allOrders = [{/*order1*/}, {/*order2*/}, {/*order3*/}];

var data1 = [
  {
    time: "10:31",
    side: "Buy",
    qty: "100",
    open: "150",
    target: "152",
    current: "149",
    pl: "-2",
  },
];

var sum = 0;
//var ur_pl = 0;
var data = [];

function OrderTable(props) {
  const [tradesData, setTradesData] = useState(null);
  const [close, setClose] = useState("");
  const [activePL, setActivePL] = useState(0);
  const [dayPL, setDayPL] = useState(0);
  const [realizedPL, setRealizedPL] = useState(0);

  let bars = useSelector((state) => state.barsArray[0]);
  let orderData = useSelector((state) => state.orderArray[0]);
  //let activities = useSelector((state) => state.activityArray[0]);
  let activities = props.data;

  let cl = parseFloat(bars ? bars[bars.length - 1].close : 0, 3);
  //console.log("activities in OT:", activities);

  let netPL = activities ? parseFloat(activities[activities.length - 1].pl) : 0;

  //console.log("orderData in OT", orderData)
  const orders = orderData.map((obj) => obj.order);

  let PL =
    activities.lenth > 0 ? parseFloat(activities[activities.length - 1].pl) : 0;

  var plList = activities.filter(
    (obj) => !isNaN(obj.open_qty) && obj.open_qty == 0
  );

  let lastItem = activities[activities.length - 1];
  if (lastItem && lastItem.open_qty !== 0) {
    lastItem.pl_delta = "Active";
    plList.push(lastItem);
  }

  var bktOrders = orders.filter(function (obj) {
    return obj.order_class == "bracket" && obj.status == "held";
  });

  const limitOrders = orders
    .filter((obj) => obj.type === "limit")
    .map((obj) => ({
      id: obj.id,
      ts: obj.created_at.substring(0, 19),
      tp: obj.limit_price,
      side: obj.side,
      tp_filled_avg_price: obj.filled_avg_price,
    }));

  const uniqueTrades = [...new Set(activities.map((item) => item.group))];

  const filledTrades = [];
  uniqueTrades.forEach((item, index, arr) => {
    filledTrades.push({
      group: item,
      trades: getTrades(item, limitOrders, orders, activities),
    });
  });

  //console.log("filled trades:", filledTrades);

  const getTradesDetails = (grp) => {
    grp = grp - 1;
    console.log("grp", filledTrades[grp])
    data = filledTrades[grp].trades.map((obj) => ({

      Time: dateToString(new Date(obj.ts + "Z")),
      Status: obj.status === "filled" ? "closed" : obj.status,
      Qty: obj.qty,

      Side: obj.side === "buy" ? "Long" : "Short Sell",
      Entry: obj.limit_filled_avg_price
        ? parseFloat(obj.limit_filled_avg_price, 3).toFixed(3)
        : 0,
      Target:obj.target,
      
      Exit: obj.tp_filled_avg_price
        ? parseFloat(obj.tp_filled_avg_price, 3).toFixed(3)
        : "",

      Duration: obj.duration ? obj.duration + "(Min)" : "",

      PL: obj.pl ? obj.pl.toFixed(2) : 0,
      //tp_filled_avg_price: obj.filled_avg_price
    }));
    setTradesData(null);
    setTradesData(data);
    //data1 = data
    //console.log("trdaesdata for table", tradesData);
    sum = data.reduce((accumulator, object) => {
      return accumulator + parseFloat(object.PL);
    }, 0);
  };

  useEffect(() => {
    //console.log("init trades:", uniqueTrades[uniqueTrades.length-1])
    getTradesDetails(uniqueTrades[uniqueTrades.length - 1]);
  }, [orderData]);

  useEffect(() => {
    if (!bars) {
      return;
    }
    let ur_pl = 0;
    let rl_pl = 0;
    let active_fl = false;
    data.forEach((item) => {
      //console.log(item);
      if (item.Status == "active" && item.Side == "Long") {
        //console.log(item.Status, item.Side);
        let pnl = (cl - item.Entry) * item.Qty;
        ur_pl += pnl;
        active_fl = true
        item.PL = pnl.toFixed(2);
      }
      if (item.Status == "active" && item.Side == "Short Sell") {
        let pnl = (item.Entry - cl) * item.Qty;
        ur_pl += pnl;
        active_fl = true
        item.PL = pnl.toFixed(2);
      }
      if (item.Status == "closed"){
        rl_pl += parseFloat(item.PL)
      }
    });
    console.log("rl_pl :", rl_pl);
    //let total = netPL + ur_pl;
    let total = netPL + ur_pl;

    setActivePL(ur_pl);
    
    if (active_fl) {
      setDayPL(total + rl_pl)
      setRealizedPL(netPL + rl_pl);
    } else {
      setDayPL(total);
      setRealizedPL(netPL);
    }
    //setDayPL(total);

    
    
  }, [bars]);

  //getTradesDetails(0)

  return (
    <div
      style={{
        padding: "5 px",
      }}
    >
      <div
        className="round-corners-16px gardient-bg-1"
        style={{ margin: "20px 0 0 30px" }}
      >
        <div>
          <br />
          <h4 style={{ color: dayPL > 0 ? "blue" : "red" }}>
            Todays Profit:{" "}
            {dayPL.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
            *
          </h4>
          <br />
        </div>
        <h4>Trade Breakdown</h4>
        <p style={{ color: realizedPL > 0 ? "blue" : "red" }}>
          Realized PL :{" "}
          {realizedPL.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
          *
        </p>
          
        <p style={{ color: activePL > 0 ? "blue" : "red" }}>
          Unrealized PL :{" "}
          {activePL.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
          *
        </p>

        <table>
          <tbody>
            <tr>
              {plList.map(function (object, i) {
                return (
                  <td obj={object} key={i}>
                    <Badge
                      id={object.group}
                      bg={object.pl_delta > 0 ? "primary" : "danger"}
                      text="light"
                      onMouseOver={(e) => getTradesDetails(e.target.id)}
                      style={{
                        border: "1px solid lightgray",
                        fontSize: "10px",
                      }}
                    >
                      {object.pl_delta}
                    </Badge>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
        <br />
      </div>
      <div>
      <div
        className="round-corners-16px gardient-bg-1"
        style={{ margin: "20px 0 0 30px" }}
      >
        {tradesData != null ? (
          <Container style={{ padding: "1rem" }}>
            Current Positions
            <Table
              style={{
                fontSize: "12px",
                textAlign: "left",
              }}
            >
              <tbody>
                {Object.keys(tradesData[0]).map((key) => (
                  <tr key={key}>
                    <td style={{ fontWeight: "bold" }}>{key}</td>
                    {tradesData.map((item, index) => (
                      <td
                        key={index}
                        style={{
                          color:
                            item[key] > 0 && key == "PL"
                              ? "blue"
                              : item[key] < 0 && key == "PL"
                              ? "red"
                              : "gray",
                        }}
                      >
                        {item[key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        ) : (
          "Empty"
        )}
      </div>
      </div>
    </div>
  );
}

export default OrderTable;
