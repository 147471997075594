import {
  ADD_BARS,
  ADD_ORDER,
  ADD_PREDICTION,
  ADD_ACTIVITY,
  CLEAR_BARS,
  CLEAR_ORDER,
  CLEAR_PREDICTION,
  CLEAR_ACTIVITY,
} from "./actions";

// Define the initial state
const initialState = {
  barsArray:[],
  orderArray: [],
  predictArray: [],
  activityArray: [],
};

// Define the reducer function
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_BARS:
      return {
        ...state,
        barsArray: [...state.barsArray, action.payload],
      };
    case ADD_ORDER:
      return {
        ...state,
        orderArray: [...state.orderArray, action.payload],
      };
    case ADD_PREDICTION:
      return {
        ...state,
        predictArray: [...state.predictArray, action.payload],
      };
    case ADD_ACTIVITY:
      return {
        ...state,
        activityArray: [...state.predictArray, action.payload],
      };
    case CLEAR_BARS:
      return {
        ...state,
        barsArray: [],
      };
    case CLEAR_ORDER:
      return {
        ...state,
        orderArray: [],
      };
    case CLEAR_PREDICTION:
      return {
        ...state,
        predictArray: [],
      };
    case CLEAR_ACTIVITY:
      return {
        ...state,
        activityArray: [],
      };
    default:
      return state;
  }
}
