import React, { useState, useEffect } from "react";
import { BrowserRouter, NavLink, Route, Switch, Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Home from "./Home";
import Login from "./Login";
import Register from "./Register";
import Dashboard from "./Dashboard";
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import { getUser, resetUserSession, getToken } from "./service/AuthService";
import NavMenu from "./components/NavMenu";
import logo from "./images/logo4.png";

import "./Header.css";

function Header(props) {
  const user = getUser();
  const name = user !== "undefined" && user ? user.username : "";

  const logoutHandler = (props) => {
    resetUserSession();
    props.history.push("/login");
  };

  const menuItems = [
    { label: "Home" },
    { label: "Dashboard" },
    { label: "Live" },
    { label: "Signup" },
    { label: "Admin" },
  ];

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  function handleLogin() {
    console.log("logging in)");
    setIsLoggedIn(true);
    window.location.href = "/Dashboard";
  }

  function handleLogout() {
    setIsLoggedIn(false);
    console.log("logging out)");
    resetUserSession();
    window.location.href = "/Home";
  }
  useEffect(() => {
    let token = getToken
    if (token) {
      const expirationTime = new Date(token.expirationTime);
      const currentTime = new Date();
      if (expirationTime.getTime() >= currentTime.getTime())
        setIsLoggedIn(true);
      else
        setIsLoggedIn(false);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <header className="header-bg">
      <div className="logo">
        {/*<div className="h1-gradient">berbul.ai</div>*/}
        <img src={logo} style={{width:"10em"}}/>
      </div>
      <div>
        <NavMenu
          items={menuItems}
          isLoggedIn={isLoggedIn}
          onLogin={handleLogin}
          onLogout={handleLogout}
        />
      </div>
    </header>
  );
}

export default Header;

/*
     <Row>
        <Col>
          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/register">Sign Up</Link>
              </li>
              <li>
                <Link to="/login">Login</Link>
              </li>
              <li>
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li>
                <Link to="/live">Live</Link>
              </li>
            </ul>
          </nav>
        </Col>
        <Col><h1>AI Trader</h1></Col>
        <Col>
          <div>
            <Link to="/">
              <button class="cta-button-secondary" onClick={logoutHandler}>
                Logout
              </button>
            </Link>
          </div>
        </Col>
      </Row>
*/
