import socketIOClient from "socket.io-client";
import config from "./config";
import React, { useEffect, useContext, useState } from "react";
import {
  Container,
  Badge,
  Row,
  Col,
  Button,
  ButtonGroup,
} from "react-bootstrap";

import { prepareData, predictionAccuracy } from "./service/PredictionAccuracy";

import OrderTable from "./components/OrderTable";
import TradeChart from "./components/TradeChart";
import GenAIPredictions from "./components/GenAIPredictions";

import prepareOrder from "./service/PrepareOrder";
import buildActivities from "./service/BuildActivity";
import ActivitySummary from "./components/ActivitySummary";
import HeatmapChart from "./components/Heatmap";
import Orders from "./components/Orders";
import CountdownTimer from "./components/timer/CountdownTimer";
import getDataAPI from "./service/apiservice";
import FeedCard from "./components/feedcard/Feedcard";

import { useSelector, useDispatch } from "react-redux";
import {
  addOrder,
  addBars,
  addActivity,
  addPrediction,
  clearOrder,
  clearBars,
  clearActivity,
  clearPrediction,
} from "./service/actions";
//import Orders from "./components/Orders";
import { getToken, resetUserSession } from "./service/AuthService";

const ENDPOINT = config.API_SOCKET_URL;

const options = {
  month: "long",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hour12: true,
  timeZone: "UTC",
};
const formatter = new Intl.DateTimeFormat("en-US", options);
//const formattedDate = formatter.format(date);

let outputArr = [];

const LiveSocket = (props) => {
  const [bars, setBars] = useState(null);
  const [predictionTS, setPredictionTS] = useState("");
  const [OrderTS, setOrderTS] = useState("");
  const [chartTS, setChartTS] = useState("");
  const [cleanBars, setCleanBars] = useState([]);
  const [rawHistory, setRawHistory] = useState([]);
  const [rawOrders, setRawOrders] = useState([]);
  const [symbol, setSymbol] = useState("");
  const [close, setClose] = useState("");
  const [chartToDisplay, setChartToDisplay] = useState([]);
  const [orderToDisplay, setOrderToDisplay] = useState([]);
  const [positionsToDisplay, setPositionsToDisplay] = useState([]);
  const [activityArray, setActivityArray] = useState([]);
  const [acts, setActs] = useState([]);
  const [openPositions, setOpenPositions] = useState(null);
  const [isActcive, setIsActive] = useState(false);
  const [marketStatus, setMarketStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [ts, setTs] = useState(null);

  const dispatch = useDispatch();
  var lastIndex = -1;
  var positions = [];

  var tempBars = [];

  useEffect(() => {
    const currentDate = new Date();
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    const formattedDate = currentDate.toLocaleDateString("en-US", options);
    setTs(formattedDate);
    console.log("token:", getToken());
    const socket = socketIOClient(ENDPOINT, {
      transports: ["websocket", "polling", "flashsocket"],
      transportOption: {
        websocket: {
          extraHeaders: { Authorization: `Bearer ${getToken()}` },
        },
      },
      reconnectionDelay: 1000,
      reconnection: true,
      reconnectionAttempts: 3,
      agent: false, // [2] Please don't set this to true
      upgrade: false,
      rejectUnauthorized: false,
      extraHeaders: { Authorization: `Bearer ${getToken()}` }, // add your token here
    });

    console.log("socket:", socket);
    if (socket.disconnected) {
      console.log("login again");
      //resetUserSession();
      //window.location.href = "/Login"
    }

    socket.on("predictionHistoryEvent", (data) => {
      setPredictionTS(data["ts"]);
      setMarketStatus(data["market_status"]);

      const rawHistory = JSON.parse(data["data"]);
      //dispatch(clearPrediction())
      //dispatch(addPrediction(rawHistory));
      console.log("new prediction history event from socket : ", rawHistory);
      setRawHistory(rawHistory);
      setIsActive(true);
    });
    socket.on("orderHistoryEvent", (data) => {
      setOrderTS(data["ts"]);
      const rawOrders = JSON.parse(data["data"]);
      console.log("new order history event from socket : ", rawOrders.length);
      setRawOrders(rawOrders);
      //console.log("raw", rawOrders);
    });
    socket.on("tradeEvent", (data) => {
      //console.log("new Trade from socket : ", data);
      const tradeBar = data["data"] ? JSON.parse(data["data"]) : {};
      setClose(tradeBar.close);
      if (marketStatus === "") {
        setMarketStatus(data["market_status"]);
      }

      if (tradeBar != {}) {
        //console.log("New trade ts :", tradeBar.t)

        let dt = new Date(tradeBar.t);
        //const timezoneOffset = dt.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
        //dt.setTime(dt.getTime() - timezoneOffset);

        const date = new Date(tradeBar.t).toLocaleString("en-US");
        setChartTS(date);
        setSymbol(tradeBar.symbol);

        dt.setSeconds(0);
        dt.setMilliseconds(0);
        //console.log("dt:", dt)

        tradeBar.t = dt
          .toISOString()
          //.replace("T", " ")
          .replace(".000Z", ".000000000Z");
        //.replace("Z", ".000000000Z");

        // tradeBar.t = dt
        //console.log("New modified TS :", tradeBar.t);
      }

      //console.log("Existing Bar:", outputArr);
      setIsActive(true);
      let newBars = [];

      if (
        outputArr.length === 0 ||
        tradeBar.t > outputArr[outputArr.length - 1].t
      ) {
        // If the outputArr is empty or the new tradeBar's timestamp is greater than the last bar in the array, add the new tradeBar to the array
        const newBars = [...outputArr, tradeBar];
        const charts = [<TradeChart key={1} data={newBars} />, <GenAIPredictions key={2} data={newBars} />];
        setChartToDisplay(charts);
        outputArr = newBars;
        dispatch(clearBars());
        dispatch(addBars(outputArr));
        //console.log("Adding new trade data");
      } else if (tradeBar.t === outputArr[outputArr.length - 1].t) {
        // If the new tradeBar has the same timestamp as the last bar in the array, replace the last bar with the new tradeBar
        //console.log("Replacing new trade data");
        const newBars = [...outputArr];
        newBars.pop(); // remove the last element
        newBars.push(tradeBar); // add the new element
        const charts = [<TradeChart key={1} data={newBars} />,  <GenAIPredictions key={2} data={newBars} />];
        setChartToDisplay(charts);
        outputArr = newBars;
        dispatch(clearBars());
        dispatch(addBars(outputArr));
      } else {
        console.log(
          "invalid time:",
          tradeBar.t,
          outputArr[outputArr.length - 1].t
        );
      }
    });
    socket.emit("getPredictionHistory", null);
    socket.emit("getOrderHistory", null);
  }, []);

  useEffect(() => {
    const prepareChartData = () => {
      //console.log("rawHistory", rawHistory);

      const preparedData = rawHistory.map(prepareData);
      //console.log("preparedData", preparedData);
      outputArr = preparedData;
      if (preparedData.length > 0) {
        dispatch(clearBars());
        dispatch(addBars(preparedData));
        setBars(preparedData);

        const lastData = preparedData[preparedData.length - 1];
        setSymbol(lastData.symbol);
        setClose(lastData.close);

        //const date = new Date(lastData.t);
        //date.setHours(date.getHours() - 5);

        const date = new Date(lastData.t).toLocaleString("en-US");
        setChartTS(date);

        dispatch(clearPrediction());
        dispatch(addPrediction(preparedData));

        setChartToDisplay([<TradeChart key={1} data={preparedData} />,  <GenAIPredictions key={2} data={preparedData} />],);
      } else {
        setChartToDisplay([]);
      }
    };

    prepareChartData();
  }, [rawHistory]);

  useEffect(() => {
    if (!rawOrders) {
      return;
    }

    dispatch(clearOrder());
    dispatch(addOrder(rawOrders));

    let act = [];
    rawOrders.forEach((row) => {
      const [activity, positions] = prepareOrder(row);
      //console.log("positions:", positions);
      if (positions.qty) setPositionsToDisplay(positions.qty);
      if (activity != null) {
        act.push(activity);
      }
    });
    setActivityArray(act);

    let acts = buildActivities(act);
    setActs(acts);

    const lastIndex = acts.map((a) => a.open_qty == 0).lastIndexOf(true);

    const orders = rawOrders.map((obj) => obj.order);
    if (lastIndex != -1 && acts.length > lastIndex) {
      let pos = openPositions;
      if (pos == null) {
        pos = {
          open: [],
          orders: orders,
          price: 0,
        };
      }
      pos.open = pos.open.concat(orders.slice(lastIndex + 1));
      setOpenPositions(pos);
    }

    dispatch(clearActivity());
    dispatch(addActivity(acts));

    if (acts.length > 0) {
      let charts1 = [];
      setOrderToDisplay([]);
      charts1.push(<OrderTable key={1} data={acts} />);
      setOrderToDisplay(charts1);
    }
  }, [rawOrders]);

  const handleLiquidate = () => {
    let resp = getDataAPI({}, "trade/liquidate");
    resp.then((output) => {
      console.log("message:", output);
      alert(output);
    });
  };

  useEffect(() => {
    dispatch(clearPrediction());
  }, []);

  return (
    <div className="gradient-bg-lite">
      <div>
        <br />
        {!isActcive ? (
          <FeedCard
            avatar="icon-1.png"
            username="AI-Trader"
            handle="berbul.ai"
            time={ts}
            content='📢 Choose our platform to seize profitable intraday trading opportunities! 💰 Our AI BOT operates exclusively during NYSE trading hours (~09:35 AM - 4:00 PM), enabling you to benefit from real-time market analysis and reliable investment decisions automatically. Thank you for partnering with us for your trading needs. 🙌 Please note that our platform is currently in "Beta" mode and limited
          to paper trading only for "SPY". ⚠️ All trades carry market risk. Past
          performance is not indicative of future results. This demonstration is
          for informational purposes only. DO NOT use for live trading. 💡
          #MinuteByMinutePredictions #MarketOpportunities'
          >
            <h3>
              <br />
              Live trading available only during Market hours. <br />
              <br />
              Access our{" "}
              <a href="/dashboard">
                <Badge bg="info">dashboard </Badge>
              </a>{" "}
              for historical insights and performances.
              <br />
              <br />
            </h3>
          </FeedCard>
        ) : (
          <div></div>
        )}

        <Row>
          {isActcive ? (
            <Container>
              <FeedCard
                avatar="icon-1.png"
                username="AI-Trader"
                handle="berbul.ai"
                time={ts}
                content="💰 #PaperTradingOnly 💡 Using Alpaca markets for zero-commission trades. 💼 $25,000 opening balance daily + margin if needed. Positions closed by EOD. PL = (Closing - Opening balance) - SEC fees. Bot places long/short orders based on AI predictions throughout the trading window. #AItrading #AlpacaMarkets"
              >
                ⚠️ All trades carry market risk. Past performance is not
                indicative of future results. This demonstration is for
                informational purposes only. DO NOT use for live trading. 💡
                #MinuteByMinutePredictions #MarketOpportunities
              </FeedCard>
              <p>Market Operation</p>
              <CountdownTimer />
              <Row>
                <Container>
                  <br />
                  <ButtonGroup role="group" className="me-2">
                    <Button variant="dark">Paper</Button>
                    <Button variant="outline-secondary" disabled={true}>
                      Live
                    </Button>
                  </ButtonGroup>
                </Container>
              </Row>
            </Container>
          ) : (
            <div></div>
          )}

          <Col xs={12} lg={8} className="round-corners-10px">
            <br />
            {!isActcive ? (
              <div
                className="round-corners-10px gardient-bg-1"
                bg="secondary"
                style={{
                  height: "auto",
                  textAlign: "start",
                  padding: "0 0 0 50px",
                  background: "white",
                }}
              ></div>
            ) : (
              <div>
                <Container
                  className="round-corners-16px"
                  style={{ background: "white", margin: "0 0 0 40px " }}
                >
                  <br />
                  <p>Predictions (for the next 60 minutes)</p>
                  <Row>
                    <Col xs={4} lg={4} style={{ textAlign: "left" }}>
                      <Badge>
                        {symbol} : {close}
                      </Badge>
                    </Col>
                    <Col xs={4} lg={4}>
                      <div className="moving-underline-container">
                        {marketStatus != "closed" ? (
                          <span className="moving-underline"></span>
                        ) : (
                          ""
                        )}
                        <p style={{ fontSize: "small", textAlign: "center" }}>
                          Status:<strong> {marketStatus}</strong>
                        </p>
                      </div>
                    </Col>
                    <Col xs={4} lg={4}>
                      <p style={{ fontSize: "small", textAlign: "right" }}>
                        {chartTS}
                      </p>
                    </Col>
                  </Row>
                  {chartToDisplay}
                </Container>
                <Container
                  className="round-corners-16px gardient-bg-1"
                  style={{ margin: "20px 0 0 40px" }}
                >
                  <HeatmapChart />
                </Container>
              </div>
            )}
          </Col>
          <Col xs={12} lg={4}>
            <br />
            {isActcive ? (
              <Container className="round-corners-10px">
                {orderToDisplay.length > 0 ? (
                  <div>
                    <div
                      className="round-corners-16px gardient-bg-1"
                      style={{ margin: "0 0 0 30px" }}
                    >
                      {" "}
                      <br />
                      <p style={{ fontSize: "small" }}>{OrderTS}</p>
                      <h4>Open Positions: {positionsToDisplay}</h4>
                      <Button variant="danger" onClick={handleLiquidate}>
                        Liquidate all {positionsToDisplay} positions
                      </Button>
                      <p></p>
                      <br />
                    </div>
                    {orderToDisplay}
                  </div>
                ) : (
                  <div className="order-summary"></div>
                )}
              </Container>
            ) : (
              <div></div>
            )}
          </Col>
        </Row>
        <br />

        <Container>
          <Orders key={1} data={rawOrders} />
          <br />
        </Container>

        <Row>
          <Container
            className="round-corners-16px gardient-bg-1"
            style={{ padding: "40px" }}
          >
            <div>
              {activityArray.length > 0 ? (
                <div>
                  Activity History
                  <ActivitySummary data={acts} key={1} />
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </Container>
        </Row>
      </div>
    </div>
  );
};

export default LiveSocket;

/*
  useEffect(() => {
    if (!rawOrders) {
      return;
    }
  
    const prepareOrders = () => {
      dispatch(clearOrder());
      dispatch(addOrder(rawOrders));
  
      const activityArray = [];
      let openOrders = [];
      rawOrders.forEach((row) => {
        const [activity, positions] = prepareOrder(row);
        if (activity) {
          activityArray.push(activity);
        }
      });
      setActivityArray(activityArray);
  
      const activities = buildActivities(activityArray);
      setActs(activities);
  
      const lastIndex = activities.map((a) => a.open_qty === 0).lastIndexOf(true);
      
      if (lastIndex !== -1) {
        const newOpenOrders = openOrders.slice(lastIndex + 1);
        setOpenPositions({
          open: newOpenOrders,
          orders: rawOrders.map((obj) => obj.order),
          price: 0,
        });
      }
  
      dispatch(clearActivity());
      dispatch(addActivity(activities));
  
      if (acts.length > 0) {
        const charts = [<OrderTable key={1} data={acts} />];
        setOrderToDisplay(charts);
      } else {
        setOrderToDisplay([]);
      }
    };
  
    prepareOrders();
  }, [rawOrders]);
  */
