
const config = {
  API_BASE_URL_LOCAL: "https://api.example.com",
  API_BASE_URL: "https://ptrx4akz5g.execute-api.us-east-1.amazonaws.com/Prod",
  //API_SOCKET_URL: "http://127.0.0.1:5000", 
  //API_SOCKET_URL: "http://192.168.86.27:5000", //Ubuntu
  //API_SOCKET_URL: "http://35.169.231.77:80", 
  API_SOCKET_URL: "https://backend.berbul.com", 
  API_KEY: "cCAE5pfpQ31MPrPq2Lowp15uJMlpCqce4tiZ8OTc",
  DEBUG_MODE: true,
  ACCURACY_WINDOW: 20
};

export default config;
