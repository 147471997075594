import { Container, Row, Col, Badge, Placeholder } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import TradeChart from "../components/TradeChart";

import { useState, useEffect } from "react";

function TradeSummary(props) {
  const [chartToDisplay, setChartToDisplay] = useState([]);
  //const [refreshFalg, setRefreshFlag] = useState(false)
  var refreshFlag = false
  const [grp_pl, setGrp_pl] = useState(10);
  const [slicePred, setSlicePred ] = useState([])
  const dateToString = d => `${d.getFullYear()}-${('00' + (d.getMonth() + 1)).slice(-2)}-${('00' + 
    d.getDate()).slice(-2)} ${d.getHours()}:${d.getMinutes()}` 

  const [myData, setMyData] = useState(null);
  const { data } = props;

  let predictArray = useSelector((state) => state.predictArray[0]);
  //console.log("predict Array inside trade summary:", predictArray);

  let orderArray = useSelector((state) => state.orderArray[0]);
  //console.log("order array :", orderArray);

  if (!predictArray) { 
    predictArray = [];
    //refreshFlag = true
  } 

  if (!orderArray) { 
    orderArray = []
  }
  var plList = data.filter(function (obj) {
    return !isNaN(obj.open_qty) && obj.open_qty == 0;
  });


  var allOrders = orderArray.map(function (obj) {
    return obj.order;
  });
  var orders = allOrders.filter(function (obj) {
    return obj.order_class == "bracket" && obj.status == "held";
  });
  
  /*
  useEffect(()=>{
    //const charts = [];
    console.log("resetting trade summary...")
    setChartToDisplay([])
    let charts = []
    charts.push(<TradeChart key={1} data={predictArray} />);
    setChartToDisplay(charts);
    
  },[])*/

  const limitOrders = orders
    .filter((obj) => obj.type === "limit")
    .map((obj) => ({
      id: obj.id,
      ts: obj.created_at.substring(0, 19),
      tp: obj.limit_price,
      side: obj.side,
      tp_filled_avg_price: obj.filled_avg_price,
    }));

  let lastItem = data[data.length - 1];

  if (lastItem.open_qty !== 0) {
    lastItem.pl_delta = "Live";
    plList.push(lastItem);
  }

  function buildTradeDetails(item, index, arr) {
    //console.log(item.ts);
    const tpOrders = limitOrders
      .filter(function (obj) {
        return obj.ts == item.ts;
      })
      .reverse();
    item.tp = tpOrders[0].tp;
    item.tp_id = tpOrders[0].id;

    const filled = allOrders.filter(function (obj) {
      return obj.status == "filled" && item.id == obj.id;
    });

    item.limit_filled_avg_price = parseFloat(
      filled[0].filled_avg_price
    ).toFixed(2);

    //console.log("tpOrders[0] :", tpOrders[0]);
    const tp_filled = allOrders.filter(function (obj) {
      return obj.status == "filled" && item.tp_id == obj.id;
    });

    const canceled = allOrders.filter(function (obj) {
      return obj.status == "canceled" && item.tp_id == obj.id;
    });

    //console.log("filled orders", tp_filled);
    //console.log("canclled orders", canceled);

    if (tp_filled.length > 0) {
      item.status = tp_filled[0].status;
      item.filled_at = tp_filled[0].filled_at.substring(0, 19);
      item.tp_filled_avg_price = parseFloat(
        tp_filled[0].filled_avg_price
      ).toFixed(2);
      item.tp_limit = parseFloat(tp_filled[0].limit_price).toFixed(2);
      let date1 = new Date(item.ts);
      let date2 = new Date(item.filled_at);
      const timeDiff = (date2.getTime() - date1.getTime()) / (1000 * 60);
      item.duration = timeDiff.toFixed(2);
      let pl = 0;

      if (item.side == "buy") {
        pl =
          (item.tp_filled_avg_price - item.limit_filled_avg_price) * item.qty;
      } else if (item.side == "sell_short") {
        pl =
          (item.limit_filled_avg_price - item.tp_filled_avg_price) * item.qty;
      }
      item.pl = pl.toFixed(2);
    } else if (canceled.length > 0) {
      //console.log("canceled[0] :", canceled[0]);
      item.status = canceled[0].status;
      item.tp_limit = parseFloat(canceled[0].limit_price).toFixed(2);
      item.canceled_at = canceled[0].canceled_at.substring(0, 19);
      let date1 = new Date(item.ts);
      let date2 = new Date(item.canceled_at);
      const timeDiff = (date2.getTime() - date1.getTime()) / (1000 * 60);
      item.duration = timeDiff;
    } else {
      item.status = "active";
    }
  }

  const getTrades = (grp) => {
    // get trdaes for a group
    //console.log("-------------------------");

    const tradeGroup = data
      .filter(function (obj) {
        return obj.group == grp;
      })
      .map(function (obj) {
        return {
          ts: obj.created_at_gmt.substring(0, 19),
          limit: obj.limit,
          qty: obj.qty,
          side: obj.side,
          id: obj.id,
        };
      });
    // get the type of trade
    let tradeSide = tradeGroup[0].side;

    const trades = tradeGroup.filter(function (obj) {
      return obj.side == tradeSide;
    });

    if (tradeSide == "sell_short") {
      tradeSide = "buy";
    } else if (tradeSide == "buy") {
      tradeSide = "sell";
    }

    trades.forEach(buildTradeDetails);

    //console.log("Trades..", trades);
    //console.log("limit orders ", limitOrders);
    setMyData(trades);

    let start = trades[0]
    let end = trades[trades.length-1]
    let window_start_ts = new Date(start.ts + "Z")
    let window_end_ts = new Date(end.ts + "Z")

    //console.log("start/end ", dateToString(window_start_ts), dateToString(window_end_ts))
    let predictIndex = predictArray.findIndex(obj => dateToString(new Date(obj.t)) === dateToString(window_start_ts));
    let predictIndexEnd = predictArray.findIndex(obj => dateToString(new Date(obj.t)) === dateToString(window_end_ts));

    const maxDuration = trades.reduce((acc, curr) => {
      return curr.duration > acc ? curr.duration : acc;
    }, 0);
    let startIdx = predictIndex > 10 ? predictIndex -10 : 0
    let endIdx = Math.ceil((maxDuration > 0 ? maxDuration : 60)) + predictIndexEnd + 5
    //console.log("PredictIndex :", predictIndex, maxDuration, endIdx)
    let slice = predictArray.slice(startIdx, endIdx)
    setSlicePred(slice)
    //console.log(slicePred)
    setGrp_pl(plList[grp-1].pl_delta)
    //console.log(grp_pl)
    const charts = [];
    setChartToDisplay([])
    charts.push(<TradeChart key={1} data={slice} />);
    setChartToDisplay(charts);
   
  };



  let activityArray = myData
    ? myData.map(
        ({
          id,
          ts,
          side,
          qty,
          limit_filled_avg_price,
          tp_limit,
          tp_filled_avg_price,
          duration,
          pl,
        }) => (
          <tr key={id}>
            <td>
              {new Date(ts + "Z").getHours()}:{new Date(ts + "Z").getMinutes()}
            </td>
            <td>{side}</td>
            <td>{qty}</td>
            <td>{limit_filled_avg_price}</td>
            <td>{tp_limit}</td>
            <td>{tp_filled_avg_price}</td>
            <td>{duration}</td>
            <td>{pl}</td>
          </tr>
        )
      )
    : null;

  return (
    <div className="rounded mb-0" style={{ border: "1px solid lightgray" }}>
  <Container>
    <Row>
      <Col>
        <h4>Intraday Trade Breakdown</h4>
      </Col>
    </Row>
    <Row>
      <Col lg={4}></Col>
      <Col xs={12} lg={4}>
        {predictArray.length > 0 ? (
          <table>
            <tbody>
              <tr>
                {plList
                  .map(function (object, i) {
                    return (
                      <td obj={object} key={i} style={{
                        backgroundColor: "lightgray",
                        padding: "1rem"
                      }}
                      >
                        <Badge
                          id={object.group}
                          bg={object.pl_delta > 0 ? "primary" : "danger"}
                          text="light"
                          onMouseOver={(e) => getTrades(e.target.id)}
                        >
                          {object.pl_delta}
                        </Badge>
                      </td>
                    );
                  })
                  }
              </tr>
            </tbody>
          </table>
        ) : (
          " "
        )}
      </Col>
      <Col lg={4}></Col>
    </Row>
  </Container>
  <br />
  <Container>
    <Row>
    <Col xs={12} lg={12}>
        <Container style={{ padding: "1rem" }}>
          <div>
            <table
              className="table table-striped"
              style={{ fontSize: "10px" }}
            >
              <thead>
                <tr>
                  <th>time</th>
                  <th>Side</th>
                  <th>Qty</th>
                  <th>Open</th>
                  <th>Target</th>
                  <th>Close</th>
                  <th>Duration</th>
                  <th>PL</th>
                </tr>
              </thead>
              <tbody>{activityArray}</tbody>
            </table>
          </div>
        </Container>
      </Col>
    </Row>
    <Row>
      <Col xs={12} lg={12}>
        {chartToDisplay.length == 0 ? (
          <Placeholder
            bg="secondary"
            style={{
              textAlign: "start",
              padding: "0 0 0 50px",
              border: "1px solid lightgray",
            }}
          >
            Please select a trade from above{" "}
          </Placeholder>
        ) : (
          <Container>{chartToDisplay}</Container>
        )}
      </Col>
      
    </Row>
  </Container>
</div>

  );
}

export default TradeSummary;
