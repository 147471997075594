import React, { useEffect, useState, memo } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const dateToString = (d) =>
  `${d.getFullYear()}-${("00" + (d.getMonth() + 1)).slice(-2)}-${(
    "00" + d.getDate()
  ).slice(-2)} ${d.getHours()}:${d.getMinutes()}`;

function Candlestick(props) {
  const opt = { timeZone: "America/New_York", timeZoneName: "short" };

  //console.log("cs2 data:", props.data)
  let predictArray = useSelector((state) => state.predictArray[0]);

  if (!predictArray) {
    predictArray = [];
  }
  console.log("cs2 data predictarray:", predictArray)

  const ohlc = predictArray.map(({ t, open, high, low, close }) => ({
    x: dateToString(new Date(t)),
    y: [parseFloat(open), parseFloat(high), parseFloat(low), parseFloat(close)],
  }));

  const min = Math.min(...ohlc.map((d) => d.y[2]));
  const max = Math.max(...ohlc.map((d) => d.y[1]));

  //console.log("inidal min/max:", min, max)
  const up = predictArray.map(({ t, up }) => ({
    x: dateToString(new Date(t)),
    y: parseFloat(up).toFixed(2),
  }));

  //console.log("up:", up)

  const hold = predictArray.map(({ t, hold }) => ({
    x: dateToString(new Date(t)),
    y: parseFloat(hold).toFixed(2),
  }));

  const down = predictArray.map(({ t, down }) => ({
    x: dateToString(new Date(t)),
    y: parseFloat(down).toFixed(2),
  }));

  const pv = predictArray.map(({ t, pv_3 }) => ({
    x: dateToString(new Date(t)),
    y: parseFloat(pv_3).toFixed(2),
  }));

  const volume = predictArray.map(({ t, volume }) => ({
    x: dateToString(new Date(t)),
    y: parseFloat(volume),
  }));

  const rsi = predictArray.map(({ t, rsi }) => ({
    x: dateToString(new Date(t)),
    y: (parseFloat(rsi).toFixed(2) - .5) / 100,
  }));
  
  const macd = predictArray.map(({ t, macd }) => ({
    x: dateToString(new Date(t)),
    y: (parseFloat(macd).toFixed(2) - .5) / 100,
  }));

  const c_1 = predictArray.map(({ t, c_1 }) => ({
    x: dateToString(new Date(t)),
    y: parseFloat(c_1),
  }));

  const mtl_fut_c = predictArray.map(({ t, mtl_fut_c }) => ({
    x: dateToString(new Date(t)),
    y: parseFloat(mtl_fut_c),
  }));

  const mtl_fut_c60 = predictArray.map(({ t, mtl_fut_c60 }) => ({
    x: dateToString(new Date(t)),
    y: parseFloat(mtl_fut_c60),
  }));

  const [series1, setSeries1] = useState({
    series: [

      { name: "UP Trend", type: "scatter", data: up },
      //{ name: "hold", type: "scatter", data: hold },
      { name: "Predicted Target", type: "scatter", data: pv },
      { name: "DOWN Trend", type: "scatter", data: down },
      { name: "candlestick", type: "candlestick", data: ohlc },
    ],
  });

  const [series2, setSeries2] = useState({
    seriesBar: [
      {
        name: "RSI",
        data: rsi,
      },
      {
        name: "MACD",
        data: macd,
      },
      {
        name: "Short Term",
        data: c_1,
      },
      {
        name: "Medium Term",
        data: mtl_fut_c,
      },
      {
        name: "Lobg Term",
        data: mtl_fut_c60,
      },
    ],
  });

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "candlestick",
      height: 400,
      id: "candles",
      toolbar: {
        autoSelected: "pan",
        show: false,
      },
      zoom: {
        enabled: false,
        type: "y",
      },
    },
    plotOptions: {
      candlestick: {
        colors: {
          upward: "#1FCEFF47",
          downward: "#FFCC0047",
        },
      },
      scatter: {
        colors: ["#1fad45", "#c71423", "#1468c7"],
      },
    },
    title: {
      text: "Prediction Chart",
      align: "left",
    },
    grid: {
      show: true,
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: function (val) {
          return new Date(val).toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          });
        },
      },
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
      min: Math.min(...ohlc.map((d) => d.y[2])),
      max: Math.max(...ohlc.map((d) => d.y[1])),
    },
  });

  const [chartOptions2, setChartOptions2] = useState({
    chart: {
      height: 300,
      type: "heatmap",

      brush: {
        enabled: true,
        passive: false,
        target: "candles",
        //autoScaleYaxis: true
      },
      selection: {
        enabled: true,
        xaxis: {
          min: ohlc.length > 0 ? new Date(ohlc.length > 30 ? ohlc[ohlc.length - 30].x: ohlc[0].x).getTime(): NaN,
          max: ohlc.length > 0 ? new Date(ohlc[ohlc.length - 1].x).getTime(): NaN,
        },
        fill: {
          color: "#ccc",
          opacity: 0.4,
        },
        stroke: {
          color: "#0D47A1",
        },
      },
      events: {
        click: function (event, chartContext, config) {
          // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
          //console.log("Click event", config);
          let min = Math.min(...ohlc.slice(-30).map((d) => d.y[2]));
          let max = Math.max(...ohlc.slice(-30).map((d) => d.y[1]));
          //console.log(min, max);

          setChartOptions({
            ...chartOptions,
            yaxis: {
              min: min,
              max: max,
              lines: {
                show: true,
              },
            },
          });
        },

        brushScrolled: function (chartContext, { xaxis, yaxis }) {
          // ...
          //console.log("scrolll", xaxis, yaxis)
          let st = dateToString(new Date(xaxis.min));
          let end = dateToString(new Date(xaxis.max));
          //console.log(st, end, ohlc[0])
          //console.log(predictArray)
          let startIndex = ohlc.findIndex((obj) => obj.x === st);
          let endIndex = ohlc.findIndex((obj) => obj.x === end);
          //console.log("idx :", startIndex, endIndex)
          let slice = ohlc.slice(startIndex, endIndex);
          let sliceup = up.slice(startIndex, endIndex);
          let slicedown = down.slice(startIndex, endIndex);
          let slicepv = pv.slice(startIndex, endIndex);
          //console.log(slice)
          let min = Math.min(...slice.map((d) => d.y[2]));
          let max = Math.max(...slice.map((d) => d.y[1]));
          //console.log("MIN/MAX",min, max);

          setChartOptions({
            ...chartOptions,
            yaxis: {
              min: min,
              max: max,
              lines: {
                show: false,
              },
            },
          });

          setSeries1({
            ...series1,
            series: [

              { name: "up", type: "scatter", data: sliceup },
              { name: "pv", type: "scatter", data: slicepv },
              { name: "down", type: "scatter", data: slicedown },
              { name: "ohlc", type: "candlestick", data: slice },
            ],
          });
        },
      },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 1,
        radius: 0,
        useFillColorAsStroke: false,
        colorScale: {
          ranges: [
            {
              from: -2,
              to: -.009,
              name: "SELL",
              color: "#FF0000",
            },
  
            {
              from: -.01,
              to: -0.00000000001,
              name: "low",
              color: "#FFD900",
            },
            {
              from:-0.00000000001,
              to: 0.00000000001,
              name: "netural",
              color: "#ffffff",
            },
            {
              from: 0.00000000001,
              to: .0009,
              name: "high",
              color: "#00667D",
            },
  
            {
              from: .009,
              to: 2,
              name: "BUY",
              color: "#00667D",
            },
          ],
        },
      },
    },
  
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: function (val) {
          return new Date(val).toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          });
        },
      },
      axisBorder: {
        offsetX: 13,
      },
    },
  });

  return (
    <div
      className="rounded mb-0"
      style={{
        height: "800px",
      }}
    >
      {series1.series ? (
        <div>
          <div id="chart-candlestick">
            <ReactApexChart
              options={chartOptions}
              series={series1.series}
              type="scatter"
              height={400}
            />
          </div>
          <div id="chart-bar">
            <ReactApexChart
              options={chartOptions2}
              series={series2.seriesBar}
              type="heatmap"
              height={200}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Candlestick;
