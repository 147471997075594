import React from "react";
import { Table, Badge } from "react-bootstrap";

function DTable({ data, handleRowClick }) {
  // Extract the dates and headers from the data object
  var acts = data;
  console.log("acts: ", data)
  return (
    <div style={{backgroundColor: 'white'}}>
      <br />
      <p>Historical Transcation Summary</p>
      <p style={{fontSize:"10px"}}>(Please select a row for daily details)</p>
      <div className="table-container">
        <Table
          hover
          data-sticky-header="true"
          style={{ height: "250px", width: "auto", minWidth:"500px", fontSize: "small", textAlign:"center"}}
        >
          <thead>
            <tr>
              <th className="sticky-col">Date</th>
              <th>Total Trades</th>
              <th># Long Trades</th>
              <th># Short Trades</th>
              <th>PL Before Fees ($)</th>
              <th>SEC Fees ($)</th>

              <th style={{background:"lightgrey"}}>Net PL ($)</th>
            </tr>
          </thead>
          <tbody style={{ cursor: "pointer" }}>
            {acts.map((act, index) => (
              <tr key={index} onClick={() => handleRowClick(act.dt)}>
                <td className="sticky-col">
                  {act.dt}
                </td>
                <td><Badge bg="primary">{act.total_trades}</Badge></td>

                <td><Badge bg="secondary">{act.long_count}</Badge></td>
                <td><Badge bg="warning">{act.short_count}</Badge></td>
                <td className={act.PL < 0 ? "loss" : "profit"}>
                  {act.PL.toFixed(2)}
                </td>
                <td className="loss">{act.total_tax.toFixed(2) * -1}</td>

                <td
                  className={act.net_pl < 0 ? "loss" : "profit"}
                  style={{background:"lightgrey"}}
                >
                  {act.net_pl.toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default DTable;

/** 
 *               <th>Profit from Long ($)</th>
              <th>Profit from Short ($)</th>
              <th>Net Profitable Trades ($)</th>
              <th>Loss from Long ($)</th>
              <th>Loss from Short ($)</th>
              <th>Net Losing trades ($)</th>
 * <td className={act.p_long > 0 ? "profit" : "loss"}>
                  {act.p_long.toFixed(2)}
                </td>
                <td className={act.p_short > 0 ? "profit" : "loss"}>
                  {act.p_short.toFixed(2)}
                </td>
                <td className={act.total_profit > 0 ? "profit" : "loss"}>
                  {act.total_profit.toFixed(2)}
                </td>
                <td className={act.l_long < 0 ? "loss" : "profit"}>
                  {act.l_long.toFixed(2)}
                </td>
                <td className={act.l_short < 0 ? "loss" : "profit"}>
                  {act.l_short.toFixed(2)}
                </td>

                <td className={act.total_loss < 0 ? "loss" : "profit"}>
                  {act.total_loss.toFixed(2)}
                </td>
*/
