import React, { useEffect, useContext, useState, startTransition, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import socketIOClient from "socket.io-client";
import axios from "axios";
import config from "./config";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { ButtonGroup, Button, Row, Col, Container } from "react-bootstrap";
import { getToken } from "./service/AuthService";
import { prepareData, predictionAccuracy } from "./service/PredictionAccuracy";
//import { prepareOrder, buildActivities } from "./service/OrderProcessing";
import prepareOrder from "./service/PrepareOrder";
import buildActivities from "./service/BuildActivity";

import PredictionChart from "./components/PredictionChart";
import ActivitySummary from "./components/ActivitySummary";
import ProgressBar from "./components/UX";
//import TradeChart from "./components/TradeChart";
import TradeSummary from "./components/TradeSummary";
import AccountSummary from "./components/AccountSummary";
import HeatmapChart from "./components/Heatmap";
import { VerticalBarSeries } from "react-vis/dist";
import Liveview from "./Live";
import { resetUserSession } from "./service/AuthService";
import DataTable from "./components/DateTable";
import FeedCard from "./components/feedcard/Feedcard";

import {
  addOrder,
  addPrediction,
  addActivity,
  clearOrder,
  clearPrediction,
  clearActivity,
} from "./service/actions";
import reducer from "./service/reducer";
import TradeChart from "./components/TradeChart";
import GenAIPredictions from "./components/GenAIPredictions";

const APIUrl = config.API_BASE_URL;
var errorMessage = "";

function getDataAPI(ts, resource, retryCount = 0) {
  return axios
    .post(
      APIUrl + "/" + resource,
      {
        startDate: ts,
        username: "admin",
        days: 30,
      },
      {
        headers: {
          "x-api-key": config.API_KEY,
          authorizationToken: getToken(),
        },
      }
    )
    .then((response) => {
      // Handle successful response
      console.log("Predictions:", response);
      return response;
    })
    .catch((error) => {
      // Handle error
      console.log("Error from API - ", error);
      errorMessage = "Error getting data. please logout and login";
      if (error.response) {
        // The request was made and the server responded with a status code
        if (error.response.status === 401 || error.response.status === 403) {
          console.log("Unable to access API - 403: ", resource);
        } else {
          console.log(
            "API returned an error -",
            error.response.status,
            error.response.data
          );
        }
        resetUserSession();
        window.location.href = "/Login";
      } else if (error.request) {
        // The request was made but no response was received

        console.log("Network error - please login");
        resetUserSession();
        window.location.href = "/Login";
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Request error -", error.message);
      }
      return [];
    });
}

const Dashboard = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [bars, setBars] = useState([]);
  const [cleanbars, setCleanbars] = useState([]);
  const [loadingHist, setLoadingHist] = useState(false);
  //const [orderArray, setOrderArray] = useState([]);
  const [activityArray, setActivityArray] = useState([]);
  const [summary, setSummary] = useState(null);
  const [ts, setTs] = useState(null);
  const [sumPL, setSumPL] = useState(null);
  const [sumTrades, setSumTrades] = useState(null);
  const [sumAvgPL, setSumAvgPL] = useState(null);
  const section1Ref = useRef(null);
  const isLive=true

  //const [errorMessage, setErrorMessage] = useState(null);

  const dispatch = useDispatch();

  const handleDataFromPC = (window, scale) => {
    if (window > 10) {
      let newBars = predictionAccuracy(window, scale, cleanbars);
      setBars(newBars);
    }
  };

  const getHistory = () => {
    const dateToString = (d) =>
      `${d.getFullYear()}-${("00" + (d.getMonth() + 1)).slice(-2)}-${(
        "00" + d.getDate()
      ).slice(-2)}`;

    startDate.setHours(9, 30, 0);
    endDate.setHours(16, 0, 0);

    let start = dateToString(startDate);
    let end = dateToString(endDate);

    //clearThings();
    dispatch(clearPrediction());
    dispatch(clearOrder());
    dispatch(clearActivity());

    //console.log("getting histoty for: ", start, end);

    setLoadingHist(true);
    getPredictions(start);

    //setLoadingOrder(true);
    getOrders(start);
  };

  const getPredictions = (start) => {
    getDataAPI(start, "predictions")
      .then((response) => {
        const rawHistory = response.data;
        //console.log(rawHistory);
        let outputArr = rawHistory.map(function (row) {
          return prepareData(row);
        });
        //console.log("cleaned", outputArr);
        setCleanbars(outputArr);

        let cb = predictionAccuracy(config.ACCURACY_WINDOW, 1, outputArr);
        console.log("clean Bars", cleanbars);
        setBars(cb);
        dispatch(addPrediction(outputArr));
        setLoadingHist(false);
      })
      .catch((error) => {
        console.log("Error getting prediction history for: ", error);
      });
  };

  const getOrders = (start) => {
    getDataAPI(start, "orders")
      .then((response) => {
        const rawData = response.data;

        let bktOrder = rawData.filter(function (obj) {
          return obj;
        });
        dispatch(addOrder(rawData));

        let outputArr = rawData.map(function (row) {
          const [activity, positions] = prepareOrder(row);
          return activity;
        });

        var trades = outputArr.filter(function (obj) {
          return obj != null;
        });

        let activities = buildActivities(trades);
        setActivityArray(activities);
        dispatch(addActivity(activities));

        setLoadingHist(false);
      })
      .catch((error) => {
        console.log("Error getting Order history for: ", error);
      });
  };

  const getSummary = (start) => {
    getDataAPI(start, "accsummary")
      .then((response) => {
        const rawData = response.data;

        console.log("summary:", rawData);
        setSummary(rawData.reverse());
        let sum = 0;
        rawData.map((x) => (sum += x.PL));
        setSumPL(sum);
        console.log("sum pl:", sumPL);
      })
      .catch((error) => {
        console.log("Error getting Order history for: ", error);
      });
  };
  const handleRowClick = (start) => {
    console.log("selected...", start);

    const dt = new Date(start);
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
      timeZone: "UTC"
    };
    const formattedDate = dt.toLocaleDateString("en-US", options);
    setTs(formattedDate);
    //clearThings();
    dispatch(clearPrediction());
    dispatch(clearOrder());
    dispatch(clearActivity());

    //console.log("getting histoty for: ", start, end);

    setLoadingHist(true);
    getPredictions(start);

    //setLoadingOrder(true);
    getOrders(start);
    section1Ref.current.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    console.log("get summary");
    getSummary();
  }, []);
  return (
    <div className="gradient-bg-lite">
      {errorMessage && (
        <p className="message" style={{ color: "red" }}>
          {errorMessage}
        </p>
      )}

      <Container>
        <br/>
        <h1>Dashboard</h1>
      </Container>

      <FeedCard
        avatar="icon-1.png"
        username="AI-Trader"
        handle="berbul.ai"
        time={ts}
        content="📊 Historical daily Profit and Loss (PL) summary for the last 30 days! Scroll and SELECT a row for intraday trade details, predictions, and performance! 💰 #PaperTradingOnly 💡 Using Alpaca markets for zero-commission trades. 💼 $25,000 opening balance daily + margin if needed. Positions closed by EOD. PL = (Closing - Opening balance) - SEC fees. Bot places long/short orders based on AI predictions throughout the trading window. #AItrading #AlpacaMarkets"
      >

          {summary != null ? (
            <DataTable data={summary} handleRowClick={handleRowClick} />
          ) : (
            <div></div>
          )}

      </FeedCard>
      <Container id="section1" ref={section1Ref}> {loadingHist ? <ProgressBar /> : <div></div>}</Container>
      <FeedCard
        avatar="icon-1.png"
        username="AI-Trader"
        handle="berbul.ai"
        time={ts}
        content="📊 #PaperTradingOnly 🤖 Our AI model requires ~30 mins of historical data before predicting trades. Trading hours: 10 AM - 3:40 PM ⏰ All positions closed at 3:55 PM ⚡️ Admin portal lets you configure settings. Chart displays avg. open positions and cumulative intraday PL. Watch our bot live in action! #AItrading"
      >
        {activityArray.length > 0 ? (
          <AccountSummary key={1} data={activityArray} />
        ) : (
          <h4
            className="rounded mb-0 filler-data"
            style={{
              height: "200px",
            }}
          >
            Please select a date for Historical Performances from the table above
          </h4>
        )}
      </FeedCard>
      <FeedCard
        avatar="icon-1.png"
        username="AI-Trader"
        handle="berbul.ai"
        time={ts}
        content="📈 Scroll the trade table for intraday trades! 🔄 Bracket orders used: Entry price, Exit price (profit target from bot's prediction), and Stop loss for volatile markets. If target not met within window (bad prediction), bot exits @ market price or executes stop loss to limit losses. 💡 #IntradayTrading #BracketOrders"
      >
        {activityArray.length > 0 ? (
          <ActivitySummary data={activityArray} />
        ) : (
          <div>Tarde Activities</div>
        )}
      </FeedCard>

      <FeedCard
        avatar="icon-1.png"
        username="AI-Trader"
        handle="berbul.ai"
        time={ts}
        content='⏱️ Minute-by-minute "signal" updates! 📈 Bot predicts Long, Medium, and Short-term market strength and pressure! Stay tuned for real-time insights and trading opportunities! 💡 #MarketSignals #BotPredictions'
      >

          <HeatmapChart />

      </FeedCard>

      <FeedCard
        avatar="icon-1.png"
        username="AI-Trader"
        handle="berbul.ai"
        time={ts}
        content="📈 Minute-by-minute predictions for the day! 🟢 Green circle: Buying opportunity with 🟠 orange circle denoting the target price. 🔴 Red circle: Short sell opportunity with 🟠 orange circle indicating the target price. Trades initiated if they can be executed at the limit price. Positions are capped by maximum quantity and/or available funds. ⚠️ All trades carry market risk. Past performance is not indicative of future results. This demonstration is for informational purposes only. DO NOT use for live trading. 💡 #MinuteByMinutePredictions #MarketOpportunities"
      >
        {bars.length > 0 ? (
          <div>

          <TradeChart data={cleanbars}/>
          <GenAIPredictions data={cleanbars} />
          </div>
        ) : (
          <div>Intraday Predictions</div>
        )}
      </FeedCard>

      


      <FeedCard
        avatar="icon-1.png"
        username="AI-Trader"
        handle="berbul.ai"
        time={ts}
        content='🔮 Minute-by-minute prediction accuracy for "Long" and "Short" trades averaged over the day! ⏰ Our AI models forecast future prices achievable within the next 60 minutes (approx). 📊 Adjust prediction window to explore accuracy variations by duration. 🎯 Also, test different prediction scales to observe accuracy changes in greedy mode! 💡 #PredictionAccuracy #AItrading'
      >

          {bars.length > 0 ? (
            <PredictionChart
              key={1}
              data={bars}
              callbackFromParent={handleDataFromPC}
            />
          ) : (
            <div>Prediction Accuracy</div>
          )}

      </FeedCard>
      <br />
    </div>
  );
};

export default Dashboard;

//      {bars.length > 0 ? <Liveview key={1} data={bars} /> : ""}
/* 
          <Container
            style={{ padding: "1rem" }}
            className="justify-content-md-center"
          >
            <Row className="justify-content-md-center">
              <Col xs lg="2" style={{ textAlign: "right" }}>
                Select a day :
              </Col>
              <Col xs lg="2">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </Col>
              <Col xs lg="2" className="justify-content-md-left">
                <Button variant="secondary" size="sm" onClick={getHistory}>
                  Submit
                </Button>
              </Col>
            </Row>
          </Container>
                <Container
        style={{ padding: "1rem" }}
        className="justify-content-md-center"
      >
        <div>
          {activityArray.length > 0 ? (
            <TradeSummary key={1} data={activityArray} />
          ) : (
            <div></div>
          )}
        </div>
      </Container>
*/
