import { getToken } from "../service/AuthService"
import config from "../config";
import axios from "axios";

const APIUrl = config.API_SOCKET_URL;

function getDataAPI(msg, resource, retryCount = 0) {
  return axios
    .post(
      APIUrl + "/" + resource,
      msg,
      /*{
        startDate: ts,
      },*/
      {
        headers: {
          "x-api-key": config.API_KEY,
          authorizationToken: getToken(),
        },
      }
    )
    .then((response) => {
      // Handle successful response
      console.log("API response", response);
      return response.data.msg;
    })
    .catch((error) => {
      // Handle error
      console.log("Error from API - ", error);
      return error;
    });
}

export default getDataAPI;
