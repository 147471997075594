import React, { useState, useEffect } from 'react';
import DigitalClock from './DigitalClock';

const getTimeLeft = (startHr, startMin) => {
  // Calculate the remaining time until 10:06 AM EST

  const targetTimezoneOffset = -1 * 60 * 60 * 1000; // Eastern Standard Time (EST) offset in milliseconds

  // Set the target time to 10:06 AM EST
  const targetTime = new Date();
  targetTime.setHours(startHr - (targetTimezoneOffset / (60 * 60 * 1000)));
  targetTime.setMinutes(startMin);
  targetTime.setSeconds(0);

  const currentTime = new Date().getTime();
  const difference = targetTime - currentTime;

  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);

  return {
    days,
    hours,
    minutes,
    seconds
  };
};

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState(getTimeLeft());

  useEffect(() => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    //console.log("currentHour:", currentHour)
    let startHr = 9
    let startMin = 6
    if (currentHour > startHr) {
        startHr = 15
        startMin = 0
    }
    const timer = setInterval(() => {
      setTimeLeft(getTimeLeft(startHr, startMin));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <DigitalClock
      days={timeLeft.days}
      hours={timeLeft.hours}
      minutes={timeLeft.minutes}
      seconds={timeLeft.seconds}
    />
  );
};

export default CountdownTimer;
