import { useSelector } from "react-redux";
import { Container, Row, Col, Table, Badge } from "react-bootstrap";
import { useState, useEffect } from "react";

const dateToString = (d) => `${d.getHours()}:${d.getMinutes()}`;

function Orders(props) {
  let orderData = useSelector((state) => state.orderArray[0]);
  const orders = props.data.map((obj) => obj.order);

  const uniqueOrders = orders.reduce((accumulator, current) => {
    const index = accumulator.findIndex((item) => item.id === current.id);

    if (index < 0) {
      accumulator.push(current);
    } else {
      accumulator[index] = current;
    }
    return accumulator;
  }, []);

  const newOrders = uniqueOrders.filter((obj) =>
    ["new", "partially_filled"].includes(obj.status)
  );

  let orderDisplayValue = newOrders.map((info) => {
    return (
      <tr key={info.id}>
        <td>{dateToString(new Date(info.submitted_at))}</td>
        <td>{info.side}</td>
        <td>{info.type}</td>
        <td>{info.status}</td>
        <td>{info.filled_qty == 0 ? info.qty : info.filled_qty}</td>
        <td>{info.limit_price}</td>
      </tr>
    );
  });
  return (
    <div>
      {newOrders.length > 0 ? (
        <div>
          <h4>Open orders</h4>
          <div></div>
          <Table striped
            style={{
              maxHeight: "250px",
              overflow: "scroll",
              fontSize: "small",
              textAlign: "left",
            }}
          >
            <thead>
              <tr>
                <th>Date</th>
                <th>Side</th>
                <th>Type</th>
                <th>Status</th>
                <th>Qty</th>
                <th>TP</th>
              </tr>
            </thead>
            <tbody>{orderDisplayValue}</tbody>
          </Table>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default Orders;
