import {BrowserRouter, Route, Switch, withRouter, useLocation  } from 'react-router-dom';
import React, { useEffect, } from "react";
import Home from './Home';
import Login from './Login';
import Register from './Register';
import Dashboard from './Dashboard';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import Header from './Header';
import Footer from './footer';
import Liveview from './Live';
import LiveSocket from './LiveSocket';
import AdminPage from './routes/admin/admin';
import ReactGA from 'react-ga4'
import { initGA, logPageView } from './service/StatCounter';
import { useHistory } from 'react-router-dom';

initGA('G-ES7SDYBBEQ');

//initGA('UA-268951563-1')

//ReactGA.initialize('G-0JVED2P3DK');
/*
ReactGA.initialize('G-0JVED2P3DK');
const trackPageview = (pathname) => {
  ReactGA.pageview(pathname);
};
*/
// Wrap the component with page tracking

/*
const TrackedHome = withRouter(TrackedRoute);
const TrackedRegister = withRouter(TrackedRoute);
const TrackedLogin = withRouter(TrackedRoute);
const TrackedDashboard = withRouter(TrackedRoute);
const TrackedLiveSocket = withRouter(TrackedRoute);
const TrackedAdminPage = withRouter(TrackedRoute);
*/

const TrackedRoute = withRouter(Route);


const RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    console.log("location:", location)
    logPageView(location.pathname);
  }, [location]);

  return null;
};


function App() {
  //const history = useHistory();
  //console.log("history:", history)



  return (
    <div className="App">

      <BrowserRouter>
      <Header />
      <div className="content">

      <Switch>

          <TrackedRoute exact path="/" component={Home} />
          <TrackedRoute exact path="/Home" component={Home} />
          <TrackedRoute path="/Signup" component={Register} />
          <TrackedRoute path="/Login" component={Login} />
          <TrackedRoute path="/Dashboard" component={Dashboard} />
          <TrackedRoute path="/Live" component={LiveSocket} />
          <TrackedRoute path="/Admin" component={AdminPage} />
        </Switch>
        <RouteTracker/>
      </div>
      <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
