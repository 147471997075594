import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { Container, Placeholder, Row, Col } from "react-bootstrap";

const options = {
  chart: {
    height: 200,
    type: "heatmap",
    zoom: {
      enabled: true,
      type: "x",
    },
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    heatmap: {
      shadeIntensity: 0.5,
      radius: 0,
      useFillColorAsStroke: false,
      colorScale: {
        ranges: [
          {
            from: -2,
            to: -0.009,
            name: "SELL",
            color: "#FF0000",
          },

          {
            from: -0.01,
            to: -0.00000000001,
            name: "low",
            color: "#FFD900",
          },
          {
            from: -0.00000000001,
            to: 0.00000000001,
            name: "netural",
            color: "#ffffff",
          },
          {
            from: 0.00000000001,
            to: 0.0009,
            name: "high",
            color: "#00667D",
          },

          {
            from: 0.009,
            to: 2,
            name: "BUY",
            color: "#00667D",
          },
        ],
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 1,
  },
  title: {
    text: "Market Analysis",
  },
  xaxis: {
    labels: {
      show: false,
    },
  },
};

const dateToString = (d) => `${d.getHours()}:${d.getMinutes()}`;

function HeatmapChart(props) {
  const opt = { timeZone: "America/New_York", timeZoneName: "short" };
  let predictArray = useSelector((state) => state.predictArray[0]);
  //console.log("predict Array inside Heatmap:", predictArray);
  if (!predictArray) {
    predictArray = [];
  }

  const rsi = predictArray.map(({ t, rsi }) => ({
    x: dateToString(new Date(t)),
    y: (parseFloat(rsi).toFixed(2) - 0.5) / 100,
  }));

  const macd = predictArray.map(({ t, macd }) => ({
    x: dateToString(new Date(t)),
    y: (parseFloat(macd).toFixed(2) - 0.5) / 100,
  }));

  const trend = predictArray.map(({ t, trend }) => ({
    x: dateToString(new Date(t)),
    y: trend == "BUY" ? 1 : trend == "SELL" ? -1 : 0,
  }));

  const signal = predictArray.map(({ t, signal }) => ({
    x: dateToString(new Date(t)),
    y: signal == "BUY" ? 1 : signal == "SELL" ? -1 : 0,
  }));

  const action_drl = predictArray.map(({ t, action_drl }) => ({
    x: dateToString(new Date(t)),
    y: action_drl == 2 ? 1 : action_drl == 0 ? -1 : 0,
  }));

  const c_1 = predictArray.map(({ t, c_1 }) => ({
    x: dateToString(new Date(t)),
    y: parseFloat(c_1)*1000,
  }));
  const mtl_fut_c = predictArray.map(({ t, mtl_fut_c }) => ({
    x: dateToString(new Date(t)),
    y: parseFloat(mtl_fut_c)*1000,
  }));
  const mtl_fut_c60 = predictArray.map(({ t, mtl_fut_c60 }) => ({
    x: dateToString(new Date(t)),
    y: parseFloat(mtl_fut_c60)*1000,
  }));

  const c_delta = predictArray.map(({ t, c_delta }) => ({
    x: dateToString(new Date(t)),
    y: parseFloat(c_delta),
  }));

  const pressure_day = predictArray.map(({ t, pressure_day }) => ({
    x: dateToString(new Date(t)),
    y: parseFloat(pressure_day),
  }));

  const pressure_BOD = predictArray.map(({ t, pressure_BOD }) => ({
    x: dateToString(new Date(t)),
    y: parseFloat(pressure_BOD),
  }));

  const series = [
    {
      name: "Pressure(Current)",
      data: c_delta,
    },
    {
      name: "Pressure(ST)",
      data: pressure_day,
    },
    {
      name: "Pressure(LT)",
      data: pressure_BOD,
    },
    {
      name: "RSI",
      data: rsi,
    },
    {
      name: "MACD",
      data: macd,
    },
    {
      name: "Strength(ST)",
      data: c_1,
    },
    {
      name: "Strength(MT)",
      data: mtl_fut_c,
    },
    {
      name: "Strength(LT)",
      data: mtl_fut_c60,
    },
    {
      name: "Trend (Up/Down)",
      data: trend,
    },
    {
      name: "DRL (Pos/Neg)",
      data: action_drl,
    },
    {
      name: "Signal(Buy/Sell)",
      data: signal,
    },

  ];
  //console.log("Heatmap: ", series)
  const n = 10; // Number of items to retrieve

  const newArray = [];
  for (const obj of series) {
    if (obj.data && Array.isArray(obj.data)) {
      const lastNItems = obj.data.slice(-n);
      newArray.push({ id: obj.name, lastNItems });
    }
  }
  //console.log("new array:", newArray  )
  
  return (
    <div>
      {predictArray.length > 0 ? (
        <div>
          <br />
          <ReactApexChart
            options={options}
            series={series}
            type="heatmap"
            height={200}
          />
        </div>
      ) : (
        <Container style={{ padding: "20px", margin: "10px"}}>
          <br />
          <Row>

          </Row>
        </Container>
      )}
    </div>
  );
}

export default HeatmapChart;
