import React from "react";
import { Container, Badge } from "react-bootstrap";

const Register = () => {
  return (
    <div>
      <Container style={{padding:"40px" }}>
        <h1>Closed Beta Announcement</h1>
        <div style={{ textAlign: "left"}}>
        <h4>Dear valued users,</h4>
        <p>New Anouncement - June 15: Our platform is now open to public in demo mode!!!<br/>
          See our Bot in action during Market hours between 10 AM EST - 4 PM EST @ <a href="/live">
                <Badge bg="danger">LIVE Tradting </Badge>
              </a>{" "}</p>
        <p>
          Rest of the platform is currently in the
          closed beta phase and only accessible to a select group of users.
          Please note that during this time, only users who have been selected
          to participate in the closed beta will be able to access the capabilities.
          We understand that many of you may be eager to try out our platform,
          and we appreciate your patience and understanding as we work to ensure
          the best possible experience for all users.
        </p>
        <p>
          We are continually working to improve our platform and look forward to
          opening it up to the public soon. We are confident that the closed
          beta phase will help us identify and address any issues and make the
          necessary improvements to deliver an exceptional user experience.
        </p>
        <p>
          Please stay tuned for updates on our progress and the public launch of
          our platform. We greatly appreciate your support and participation in
          our closed beta, and we look forward to hearing your thoughts and
          suggestions.
        </p>
        <p>
          Best regards,
          <br />
          AI Trader team
        </p>
        </div>
      </Container>
    </div>
  );
};

export default Register;
