import React from "react";

const TooltipContent = ({ label }) => {
  //console.log("lABEL in tool tip:", label);
  if (!label) {
    return null; // Return null for empty tooltip when label is undefined
  }
  const getActionColor = (action) => {
    return action === "BUY" ? "#4CAF50" : "#F44336";
  };

  const getIcon = (key) => {
    const icons = {
      Assessment: "⚖️",
      BaseModelAction: "🤖",
      Confidence: "📊",
      Reason: "💡",
      RecommendedAction: "🎯",
      stop_loss: "🛑",
      take_profit: "💰",
    };
    return icons[key] || "ℹ️";
  };

  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        borderRadius: "8px",
        padding: "12px",
        maxWidth: "600px",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <div
        style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "8px" }}
      >
        {" "}
        {label?.ts || "None"}
        {getIcon("RecommendedAction")} Recommended Action:
        <span
          style={{ color: getActionColor(label?.RecommendedAction || "HOLD") }}
        >
          {label?.RecommendedAction || "None"}
        </span>
        <span
          style={{
            float: "right",
            backgroundColor: "#2196F3",
            color: "white",
            padding: "2px 6px",
            borderRadius: "12px",
            fontSize: "14px",
          }}
        >
          {label.Confidence}%
        </span>
      </div>
      <div style={{ fontSize: "14px", color: "#555", marginBottom: "8px" }}>
        {getIcon("BaseModelAction")} Prediction Model:
        <span style={{ color: getActionColor(label.BaseModelAction) }}>
          {label.BaseModelAction}
        </span>
      </div>
      <div style={{ fontSize: "14px", color: "#555", marginBottom: "8px" }}>
        {getIcon("Assessment")} Assessment: {label.Assessment}
        {label.Assessment === true ? (
          <span style={{ color: "green" }}>Agree</span>
        ) : (
          <span style={{ color: "red" }}>Disagree</span>
        )}
      </div>
      <div
        style={{
          fontSize: "14px",
          color: "#333",
          marginTop: "12px",
          wordWrap: "break-word",
          overflowWrap: "break-word",
          hyphens: "auto",
          whiteSpace: "pre-wrap",
        }}
      >
        {getIcon("Reason")} <strong>Reason:</strong>
      </div>
      <div
        style={{
          fontSize: "12px",
          color: "#666",
          marginTop: "4px",
          lineHeight: "1.4",
          wordWrap: "break-word",
          overflowWrap: "break-word",
          hyphens: "auto",
          whiteSpace: "pre-wrap",
          maxWidth: "100%",
        }}
      >
        {label.patterns !== null && (
          <div style={{ marginRight: "8px", fontSize: "14px" }}>
            Patterns: {label.patterns}
          </div>
        )}
        {label.Reason}
      </div>
      {label.stop_loss !== null && (
        <div style={{ fontSize: "14px", color: "#555", marginTop: "8px" }}>
          {getIcon("stop_loss")} Stop Loss: {label.stop_loss}
        </div>
      )}
      {label.take_profit !== null && (
        <div style={{ fontSize: "14px", color: "#555", marginTop: "8px" }}>
          {getIcon("take_profit")} Take Profit: {label.take_profit}
        </div>
      )}
    </div>
  );
};

export default TooltipContent;
