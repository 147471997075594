import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import stepImage from "./images/brokersteps.png";
import demovideo from "./images/demovideo.gif";
import axios from "axios";

const Home = () => {
  useEffect(() => {
    const cards = document.querySelectorAll(".card1");
    const windowHeight = window.innerHeight;

    const checkScroll = () => {
      for (let i = 0; i < cards.length; i++) {
        const card = cards[i];
        const cardTop = card.getBoundingClientRect().top;

        if (cardTop < windowHeight - 100) {
          card.classList.add("appear");
        }
      }
    };

    window.addEventListener("scroll", checkScroll);
    checkScroll();
  }, []);

  return (
    <div>
      <div>
        <div className="bg-container">
          <Row>
            <Col xs lg="12" order-lg-1>
              <br />
              <br/>
              <br />
              <div className="heading">Your Day Trading Assistant</div>
              <h3>
              Master Markets with Cutting-Edge Predictive Insights
              </h3>
              <br/>
              <br/>
              <div className="sub-heading">
              BerBul equips traders with advanced ML models enhanced by Generative AI, delivering unparalleled market analysis and adaptive trading signals. <br/> Gain a competitive edge with AI-driven predictions and comprehensive pattern recognition, <br/>ensuring you execute trades with precision and confidence across any timeframe.
              </div>
            </Col>
          </Row>

          <br />
          <br />
          <Row>
            <Link to="/Signup">
              <button className="cta-button">Try a Demo</button>
            </Link>
          </Row>
          <br />
          <br />
          <br/>
        </div>

        <br />
        <Row>
          <Col lg="12" order-lg-1>
            <div class="sub-header">
              <br />
              <h3 className="h1-gradient">
                Discover the Future of Day Trading <br />
                with our Revolutionary Platform. <br />
              </h3>
            </div>
          </Col>
        </Row>

        <br />

        <Row>
          <Col xs lg="3"></Col>
          <Col xs="12" lg="6">
            <main>
              <div className="line"></div>
              <div className="card1">
                <h4>Intelligent Trading</h4>
                <br />
                <ul style={{ textAlign: "left" }}>
                  AI-based trading algorithms, powered by our deep learning
                  models and bolstered by a robust risk management system to
                  invest with confidence.
                </ul>
              </div>
              <div className="card1">
                <h4>Superior Risk Management</h4>
                <br />
                <ul style={{ textAlign: "left" }}>
                  Eliminates emotions from the trading process to 
                  develop consistent trading strategies, resulting in
                  enhanced risk management and discipline.
                </ul>
              </div>
              <div className="card1">
                <h4>AI controlled Scalping</h4>
                <br />
                <ul style={{ textAlign: "left" }}>
                  Provides high-profit potential, low-risk exposure, quick
                  turnaround, and the flexibility to adjust to changing market
                  conditions, making it an ideal choice for investors seeking a
                  balanced and lucrative portfolio
                </ul>
              </div>

              <div className="card1">
                <h4>Efficient Trading Strategies</h4>
                <br />
                <ul style={{ textAlign: "left" }}>
                  Equipped with built-in market knowledge and optimized
                  long-short strategies, providing you with an edge in the
                  market and make profitable trading decisions with ease.
                </ul>
              </div>
              <div className="card1">
                <h4>Adaptive Market Intelligence</h4>
                <br />
                <ul style={{ textAlign: "left" }}>
                  Designed to analyze complex patterns, adapt to changing market
                  conditions, and optimize trading strategies in real-time,
                  ensuring that you stay ahead of the curve and maximize your
                  profits.
                </ul>
              </div>
              <div className="card1">
                <h4>Maximize Your Profits</h4>
                <br />
                <ul style={{ textAlign: "left" }}>
                  Enjoy real-time portfolio tracking, customizable trading
                  parameters, and the ability to backtest and optimize your
                  strategies, giving you the tools to maximize your investment
                  goals.
                </ul>
              </div>
            </main>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Home;

/** 
                <div class="front">Let Your New Best Friend Take the Wheel</div> 
                
                <Row>
          <Col xs="3" lg="3" order-lg-1></Col>
          <Col xs="12" lg="2" order-lg-2>
            <div class="circle-banner mx-auto">
              <h1>1</h1>Open Account <br/> with supported Brokers
            </div>
          </Col>
          <Col xs="12" lg="2" order-lg-3>
            <div class="circle-banner mx-auto">
              <h1>2</h1>Link your Brokerage <br />
              Account
            </div>
          </Col>
          <Col xs="12" lg="2" order-lg-4>
            <div class="circle-banner mx-auto">
              <h1>3</h1>Enable AutoTrade <br />
            </div>
          </Col>
          <Col xs="3" lg="3" order-lg-5></Col>
        </Row>
                <Row>
          <Col xs="12" lg="2" order-lg-1></Col>
          <Col xs="12" lg="8" order-lg-2>
            <div class="flip-container">
              <div class="clear1">
                <div class="front" style={{ backgroundColor: "white" }}>
                  <div>
                    <img
                      src={demovideo}
                      alt="your-image-alt-text"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs="12" lg="2" order-lg-1></Col>
        </Row>
                */
