import { Container, Row } from "react-bootstrap";

const dateToString = (d) => `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;

const ActivitySummary = ({ data}) => {
  const activityArray = data
    .map((info) => {
      //console.log("Activities:", data)
      return (
        <tr key={info.a_id}>
          <td>{info.group}</td>
          <td>{info.date}</td>
          <td>{dateToString(new Date(info.created_at_gmt))}</td>
          <td>{dateToString(new Date(info.submitted_at_gmt))}</td>
          <td>{info.symbol}</td>
          <td>{info.type}</td>
          <td>{info.side}</td>
          <td>
            fill: {info.price}
            <br />ask: {info.limit}
          </td>

          <td>{info.qty}</td>
          <td>{info.open_qty}</td>
          <td>{info.pl_delta}</td>
          <td>{info.tax}</td>
          <td>{info.pl}</td>

        </tr>
      );
    })
    .reverse();
  return (
    <Container>
      <Row>
        <div className="table-container" style={{ height: "400px", overflow: "auto", width: "1200px" }}>
          <table
            className="table table-striped"
            style={{fontSize:"small", minWidth: "500px"}}
          >
            <thead>
              <tr>
                <th>Trade<br/>Group</th>
                <th>Filled <br/>at</th>
                <th>Created <br/>at</th>
                <th>Submitted <br/>at</th>
                <th>Symbol</th>
                <th>Type</th>
                <th>Side</th>
                <th>Price</th>
                <th>Trade <br/>Quantity</th>
                <th>Open Positions</th>
                <th>Group <br/>PL</th>
                <th>SEC <br/>Fees</th>
                <th>Total <br/>PL</th>

              </tr>
            </thead>
            <tbody>{activityArray}</tbody>
          </table>
        </div>
      </Row>
    </Container>
  );
};

export default ActivitySummary;
