import './spinner.css'
const Spinner = ({}) => {
    <div className="spinner-container">
      <div className="spinner">Fetching data</div>
    </div>;
  }

const ProgressBar = () => {
    return (
      <div className="progress-container">
        <div className="progress-bar"></div>
        <div> Fetching data...</div>
      </div>
    );
  }

export default ProgressBar;
