import React, { useState } from "react";
import axios from "axios";
import { setUserSession } from "./service/AuthService";
import config from "./config";
//import './form.css';

//const loginAPIUrl = "http://localhost:5000/login";
const APIUrl = config.API_BASE_URL + "/login";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (username.trim() === "" || password.trim() === "") {
      setErrorMessage("Valid user name and password required");
      return;
    }
    setErrorMessage(null);
    // submit form data to backend API or perform login logic
    const encodedString = btoa(`${username}:${password}`);

    axios
      .post(
        APIUrl,
        {
          username: username,
          password: password,
        },
        {
          headers: {
            //'Authorization':`Basic ${encodedString}`,
            //'Content-Type': 'application/x-www-form-urlencoded'
            "x-api-key": config.API_KEY,
          },
        }
      )
      .then((response) => {
        // Handle successful response
        setUserSession(response.data.username, response.data.token);
        props.history.push("/dashboard");
      })
      .catch((error) => {
        // Handle error
        if (error.response.status === 401 || error.response.status === 403) {
          setErrorMessage("Login failed");
        } else {
          setErrorMessage("Server down. Please try again later!");
        }
      });
  };

  return (
    <div>
      <br />
      <h4>
        Welcome Beta users! 
      </h4>

      <br />
      <form onSubmit={handleSubmit}>
        <label>
          username:
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </label>
        <br />
        <label>
          Password:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <br />
        <button class="cta-action" type="submit">
          Login
        </button>
      </form>
      {errorMessage && <p className="message" style={{color:"red"}}>{errorMessage}</p>}
    </div>
  );
};

export default Login;
