
const buildActivities = function(activities) {
        let tradeActivities = [];
        let sale_amt = 0;
        let buy_amt = 0;
        let short_qty = 0;
        let long_qty = 0;
        let open_qty = 0;
        let delta = 0;
        let pl = 0;
        let ur_pl = 0;
        let group = 0;
        let newTrade = true;
    
        const calculateTaxes = (a)=> {
          if (a.side === "sell" || a.side === "sell_short") {
            let qty = a.qty
            let price = parseFloat(a.price)
            const FINRA_TAX = 0.000119
            const SEC_FEES_LOT = 22.1
            let SEC_cost_per_trade = ((price * qty) / 1000000) * SEC_FEES_LOT
            let finra_cost = (qty * FINRA_TAX).toFixed(2)
            let total_fees = SEC_cost_per_trade + finra_cost
            return parseFloat(total_fees).toFixed(2)
          }
          else {
            return 0.00
          }
        }
    
        for (const activity of activities) {
          if (newTrade) {
            group += 1;
            newTrade = false;
          }
          var dt = new Date(activity.transaction_time).toLocaleString("en-US");
          if (activity.side === "sell" || activity.side === "sell_short") {
            sale_amt =
              sale_amt + parseInt(activity.qty) * parseFloat(activity.price);
            short_qty = short_qty + parseInt(activity.qty);
          } else if (activity.side === "buy") {
            buy_amt = buy_amt + parseInt(activity.qty) * parseFloat(activity.price);
            long_qty = long_qty + parseInt(activity.qty);
          }
    
          open_qty = long_qty - short_qty;
    
          // console.log("calc :", open_qty, long_qty, short_qty)
    
          if (open_qty === 0) {
            delta = sale_amt - buy_amt - pl;
            pl = sale_amt - buy_amt;
            newTrade = true;
          } else {
            delta = 0;
          }
    
          const info = {
            group: group,
            id: activity.id,
            symbol:activity.symbol,
            a_id: activity.activity_id,
            date: dt,
            created_at: activity.created_at,
            created_at_gmt: activity.created_at_gmt,
            submitted_at: activity.submitted_at,
            submitted_at_gmt: activity.submitted_at_gmt,
            dateString: dt.toString(),
            price: activity.price,
            qty: activity.qty,
            side: activity.side,
            type: activity.type,
            limit: activity.limit,
            pl: pl.toFixed(2),
            ur_pl: ur_pl,
            pl_delta: delta.toFixed(2),
            cum_qty: activity.cum_qty,
            open_qty: long_qty - short_qty,
            //dateString: dt.toString(),
            buy: activity.side === "buy" ? activity.price : 0,
            sell: activity.side === "sell" ? activity.price : 0,
            sell_short: activity.side === "sell_short" ? activity.price : 0,
            pressure_BOD: isNaN(activity.pressure_BOD)
              ? 0
              : activity.pressure_BOD.toFixed(3),
            pressure_window: isNaN(activity.pressure_window)
              ? 0
              : activity.pressure_window.toFixed(3),
            c_delta: isNaN(activity.c_delta) ? 0 : activity.c_delta.toFixed(3),
          };
          let tax = calculateTaxes(info)
          info.tax = tax;
          tradeActivities.push(info);
        }
    
        return tradeActivities;
      }

export default buildActivities