import React, { useState, useEffect } from "react";
import { ButtonGroup, Button, Row, Col, Container } from "react-bootstrap";
import axios from "axios";
import config from "../../config";
import { getToken, resetUserSession } from "../../service/AuthService";
import { debounce } from "lodash";

function AdminPage() {
  const [isTrading, setIsTrading] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const debounceDelay = 500;
  const [cfg, setCfg] = useState({});

  const APIUrl = config.API_SOCKET_URL;

  function getBackendAPI(resource, msg) {
    try {
      let resource = "trade/config";
      const response = axios.get(APIUrl + "/" + resource, {
        headers: {
          "x-api-key": config.API_KEY,
          authorizationToken: getToken(),
        },
      });
      return response;
    } catch (error) {
      console.log("Error: Not Authorized", error);
      return error;
    }
  }
  async function callBackendAPI(resource, msg) {
    try {
      const response = await axios.post(APIUrl + "/" + resource, msg, {
        headers: {
          "x-api-key": config.API_KEY,
          authorizationToken: getToken(),
        },
      });
      // Handle successful response
      console.log("Message: ", response);
      return response;
    } catch (error) {
      // Handle error
      console.log("Error from API - ", error);
      setErrorMessage("Error getting data:" + error);
      resetUserSession();

      if (error.response) {
        // The request was made and the server responded with a status code
        if (error.response.status === 401) {
          console.log("Unable to access API - 401: ", resource);
          alert("Unable to access API - 401");
        } else {
          alert("Unable to access API - password Expired");
          window.location.href = "/Login";
          console.log(
            "API returned an error -",
            error.response.status,
            error.response.data
          );
        }
        //resetUserSession();
        //window.location.href = "/Login";
      } else if (error.request) {
        // The request was made but no response was received
        console.log("Network error - please login");
        //resetUserSession();
        //window.location.href = "/Login";
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Request error -", error.message);
      }
      return null;
    }
  }

  function putBackendAPI(msg) {
    axios
      .put(APIUrl + "/trade/config", msg, {
        headers: {
          "x-api-key": config.API_KEY,
          authorizationToken: getToken(),
        },
      })
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }

  const debouncedHandleValueChange = debounce((event, key) => {
    const newValue = event.target.value;
  
    setCfg((prevState) => {
      const newState = { ...prevState };
      newState[key] = newValue;
      let msg = {
        config: newState,
      };
      putBackendAPI(msg);
  
      return newState;
    });
  }, debounceDelay);

  const startTrading = async () => {
    let msg = { action: "start" };
    let resource = "trade/action";
    let resp = await callBackendAPI(resource, msg);
    console.log("Response:", resp);
    if (resp.status === 200) {
      setIsTrading(true);
      setErrorMessage("Server started");
    } else {
      console.log("Error starting trade server");
    }
  };

  const stopTrading = async () => {
    let msg = { action: "stop" };
    let resource = "trade/action";
    let resp = await callBackendAPI(resource, msg);
    console.log("Response:", resp);
    if (resp.status === 200) {
      setIsTrading(false);
      setIsPaused(false);
      // stop trading logic here
      setErrorMessage("Server stopped");
    } else {
      console.log("Error stopping trade server");
    }
  };

  const pauseTrading = () => {
    setIsPaused(true);

    setCfg((prevState) => {
      const newState = { ...prevState };
      newState["play"] = "pause";
      let msg = {
        config: newState,
      };
      putBackendAPI(msg);
      return newState;
    });
  };

  const resumeTrading = () => {
    setIsPaused(false);
    // resume trading logic here
    setCfg((prevState) => {
      const newState = { ...prevState };
      newState["play"] = "active";
      let msg = {
        config: newState,
      };
      putBackendAPI(msg);
      return newState;
    });
  };

  useEffect(() => {
    let resource = "trade/config";
    let resp = getBackendAPI(resource, null);
    resp
      .then((response) => {
        // Handle successful response
        console.log(response.data);
        setCfg(response.data.msg);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
        setErrorMessage("Error: Not AuthorizedNot Authorized");
        if (error.response.status === 401) {
          console.log(
            "Not authorized to use this page: ",
            error.response.statusText
          );
          setErrorMessage("Error: Not Authorized ", error.response.statusText);
          alert(error.response.statusText);
        } else {
          console.log(
            "API returned an error -",
            error.response.status,
            error.response.data
          );
        }
      });
  }, []);

  const updateConfig = (resource, msg) => {};

  const handleValueChange = (event, key) => {
    const newValue = event.target.value;
    
    setCfg((prevState) => {
      const newState = { ...prevState };
      newState[key] = newValue;
      let msg = {
        config: newState,
      };
      putBackendAPI(msg);

      return newState;
    })
  };

  const handleValueChangeDebounce = (event, key) => {
    event.persist(); // Preserve the event object for debouncing
    debouncedHandleValueChange(event, key);
  };

  const rows = Object.entries(cfg).map(([key, value]) => (
    <tr key={key}>
      <td style={{ textAlign: "right" }}>{key}</td>
      <td>
        <input
          type="text"
          value={value}
          onChange={(event) => handleValueChange(event, key)}
        />
      </td>
    </tr>
  ));

  return (
    <Container>
      <Row>
        <h1>Admin Page</h1>
      </Row>
      <hr />
      <Row>
        <Col sm={4}>
          {isTrading ? (
            <ButtonGroup>
              {isPaused ? (
                <Button variant="success" onClick={resumeTrading}>
                  Resume Trading
                </Button>
              ) : (
                <Button variant="warning" onClick={pauseTrading}>
                  Pause Trading
                </Button>
              )}
              <Button variant="danger" onClick={stopTrading}>
                Stop Trading
              </Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              <Button variant="success" onClick={startTrading}>
                Start Trading
              </Button>
              <Button variant="danger" onClick={stopTrading}>
                Stop Trading
              </Button>
            </ButtonGroup>
          )}
        </Col>
        <Col sm={4}>
          <p>Current Server Status:</p>
          <p>Current Trade Status:</p>
        </Col>
        <Col sm={4}>
          <h4>{isTrading ? "Trading" : "Stopped"}</h4>
          <h4>{isTrading ? (isPaused ? "Paused" : "Active") : "N/A"}</h4>
        </Col>
      </Row>
      <Row>{errorMessage}</Row>
      <hr />
      <Row>
        <Col xs={12} lg={4} className="card">
          <h3>Configuration parameters</h3>
          <table>
            <thead>
              <tr>
                <th style={{ textAlign: "right" }}>ID</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminPage;
