import ReactGA from 'react-ga4';

export const initGA = (trackingID) => {
  ReactGA.initialize(trackingID);
};

export const logPageView = (pathname) => {
  console.log(`Logging page view: ${pathname}`)
  //ReactGA.set({ page: window.location.pathname });
  //ReactGA.pageview(window.location.pathname);
  //ReactGA.set({ page:pathname });
  //ReactGA.pageview(pathname);
  ReactGA.send({ hitType: "pageview", page: pathname, title: pathname });
};

export const logEvent = (category, action) => {
  console.log(`Logging event: ${category} - ${action}`);
  ReactGA.event({ category, action });
};
