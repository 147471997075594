let gen_msg_mock = { 'Assessment': false,
  'BaseModelAction': 'HOLD',
  'Confidence': 90.0,
  'Reason': 'The chart shows a clear upward trend. The RSI is above 50, indicating that the market is bullish. The MACD is also above the signal line, confirming the bullish trend. Furthermore, the momentum stochastic is above 50, which is a sign of positive momentum.  Overall, all indicators suggest that the market is likely to continue to rise in thenext 30 minutes.  Base models HOLD action is false as the price is currently rising and there is a strong trend and momentum',
  'RecommendedAction': 'BUY',
  'patterns': ['Bullish engulfing'],
  'stop_loss': 495.0,
  'take_profit': 496.5,
  'limit_price': 496
}

let news_mock ={
  'headline': 'Salesforce CEO Marc Benioff Posts On X &#34;Introducing Einstein Service Agent on Salesforce&#39;s new Agentforce Platform! Experience intelligent customer support with seamless collaboration between digital &amp; human workers for rapid case resolution....&#34;',
}
let gen_msg_blank = { 'Assessment': false,
  'BaseModelAction': 'HOLD',
  'Confidence': 0,
  'Reason': ' None ',
  'RecommendedAction': 'None',
  'patterns': 'NOne',
  'stop_loss': 0,
  'take_profit': 0,
  'limit_price': 0
}

module.exports = {
    prepareData: function(jsonData) {
        var res = jsonData["res"];
        var ohlc = jsonData["ohlc"];
        var signal = jsonData["signal"];
        let action_drl = jsonData["action_drl"];
        let trend = jsonData["trend"];
        let pressure_day = jsonData["pressure_day"];
        let pressure_BOD = jsonData["pressure_BOD"];
        let c_delta = jsonData["c_delta"];
        let symbol = jsonData["symbol"]
        let gen_msg = jsonData["gen_ai_msg"] ?? {};
        let news = jsonData["news"] ?? null;
        //console.log("Message - prepare data");
      
        //Prediction
        let c_1 = res["c_1"];
        let h_1 = res["h_1"];
        let l_1 = res["l_1"];
        let fut_c = res["fut_c"];
        let fut_h = res["fut_h"];
        let fut_l = res["fut_l"];
        let mtl_fut_c = res["mtl_fut_c"];
        let mtl_fut_h = res["mtl_fut_h"];
        let mtl_fut_l = res["mtl_fut_l"];
        let trc_fut_c = res["trc_fut_c"];
        let trc_fut_h = res["trc_fut_h"];
        let trc_fut_l = res["trc_fut_l"];
        let rsi = res["rsi"];
        let macd = res["macd"];



        let result_mtl_fc60 = res['mtl_fut_c60']
      
        let max_target = Math.max(mtl_fut_c);
        let min_target = Math.min(mtl_fut_c);
      
        let pv = null;
        let pv_end = null;
        let pv_scale = 5.0;

        if (signal === "BUY") {
          pv = parseFloat(ohlc.c) * (1 + (max_target * pv_scale));
          pv_end = parseFloat(ohlc.c) * (1 + (min_target * pv_scale));
          //console.log("pv:", ohlc.c, max_target, pv_scale, pv)
        } else if (signal == "SELL") {
          pv = parseFloat(ohlc.c) * (1 + (min_target * pv_scale));
          pv_end = parseFloat(ohlc.c) * (1 + (max_target * pv_scale));
        }
        
        let obsMax = null;
        let obsMin = null;
        let last20 = null;
 
        let dt_str = ohlc.t

        //console.log("type:", typeof(dt_str))
        
        if (dt_str.includes("T")) {
          dt_str = dt_str + "Z"
        } else {
          dt_str = dt_str.replace('-04:00','')
        }
        //console.log("predict time: ", ohlc.t)
        

        //dt_str = dt_str + 'Z'
        let trade = {
          t: dt_str,
          date: new Date(dt_str).toLocaleString("en-US"),
          symbol:symbol,
          open: ohlc.o,
          high: ohlc.h,
          low: ohlc.l,
          close: ohlc.c,
          volume: ohlc.v,
          up: signal == "BUY" ? ohlc.c : null,
          down: signal == "SELL" ? ohlc.c : null,
          hold: signal == "HOLD" ? ohlc.c : null,
          pv: pv != null ? pv.toFixed(2) : null,
          pv_3: pv != null ? pv.toFixed(2) : null,
          pv_end: pv_end != null ? pv_end.toFixed(2) : null,
          o_max: obsMax,
          o_min: obsMin,
          max_target:max_target,
          min_target: min_target,
          c_1: c_1,
          h_1: h_1,
          l_1: l_1,
          mtl_fut_c: mtl_fut_c,
          mtl_fut_h: mtl_fut_h,
          mtl_fut_l: mtl_fut_l,
          mtl_fut_c60: result_mtl_fc60,
          lstm_fut_c: fut_c,
          lstm_fut_h: fut_h,
          lstm_fut_l: fut_l,
          trc_fut_c: trc_fut_c,
          trc_fut_h: trc_fut_h,
          trc_fut_l: trc_fut_l,
          pressure_day: pressure_day,
          pressure_BOD: pressure_BOD,
          c_delta: c_delta,
          signal: signal,
          rsi: rsi,
          macd: macd,
          pv_sclae: pv_scale,
          action_drl: action_drl,
          trend:trend,
          gen_ai_msg: gen_msg,
          news: news
        };
        return trade
      },

    predictionAccuracy: function(window, scale, bars) {
      // console.log("Calculating accuracy:", bars.length, window, scale)
      let predictArray = [];
  
      if (bars.length < window) {
        console.log("not enough prediction...");
        //return [];
      }
  
      let obsMax = null;
      let obsMin = null;
  
      for (let i = window; i < bars.length; i++) {
        let lastn = bars.slice(i, i + window);
        obsMax = Math.max.apply(
          Math,
          lastn.map(function(o) {
            return o.high;
          })
        );
        obsMin = Math.min.apply(
          Math,
          lastn.map(function(o) {
            return o.low;
          })
        );
        let pv = null;
        let pv_end = null;
        if (bars[i].signal === "BUY") {
          pv = parseFloat(bars[i].close) * (1 + (bars[i].max_target * scale));
          pv_end = parseFloat(bars[i].close) * (1 + (bars[i].min_target * scale));
        } else if (bars[i].signal == "SELL") {
          pv = parseFloat(bars[i].close) * (1 + (bars[i].min_target * scale));
          pv_end = parseFloat(bars[i].close) * (1 + (bars[i].max_target * scale));
        }
        bars[i].pv = pv

        let prow = {
          date: bars[i].date,
          high:bars[i].high,
          low:bars[i].low,
          o_max: obsMax,
          o_min: obsMin,
          pv: bars[i].pv,
          //pv: pv != null ? pv.toFixed(2) : null,
          //pv_end: pv_end != null ? pv_end.toFixed(2) : null,
          up: bars[i].up,
          down: bars[i].down,
          hold: bars[i].hold,
          correct_up: bars[i].up != null && obsMax >= bars[i].pv ? 1 : 0,
          incorrect_up: bars[i].up != null && obsMax < bars[i].pv ? 1 : 0,
          correct_down: bars[i].down != null && obsMin <= bars[i].pv ? 1 : 0,
          incorrect_down: bars[i].down != null && obsMin > bars[i].pv ? 1 : 0,
          correct_up_pct: 0,
          correct_down_pct: 0,
          correct_pct: 0,
        };
        predictArray.push(prow);
      }
  
      let cum_correct_up = 0;
      let cum_incorrect_up = 0;
      let cum_correct_down = 0;
      let cum_incorrect_down = 0;
      let correct_up_pct = 0;
      let correct_down_pct = 0;
      let correct_pct = 0;
  
      for (let i = 0; i < predictArray.length - window; i++) {
        cum_correct_up += predictArray[i + window].correct_up;
        cum_incorrect_up += predictArray[i + window].incorrect_up;
        cum_correct_down += predictArray[i + window].correct_down;
        cum_incorrect_down += predictArray[i + window].incorrect_down;
        correct_up_pct = (
          (cum_correct_up / (cum_correct_up + cum_incorrect_up)) *
          100
        ).toFixed(2);
        correct_down_pct = (
          (cum_correct_down / (cum_correct_down + cum_incorrect_down)) *
          100
        ).toFixed(2);
        correct_pct = (
          ((cum_correct_up + cum_correct_down) /
            (cum_correct_up +
              cum_correct_down +
              cum_incorrect_up +
              cum_incorrect_down)) *
          100
        ).toFixed(2);

        predictArray[i].correct_up_pct = correct_up_pct;
        predictArray[i].correct_down_pct = correct_down_pct;
        predictArray[i].correct_pct = correct_pct;
      }
  
      return predictArray;
    }
  }
  