import React, { memo } from "react";
import ReactApexChart from "react-apexcharts";
import TooltipContent from "./GenAIToolTip";  
import * as ReactDOMServer from 'react-dom/server';

function GenAIChart(props) {
  const opt = { timeZone: "America/New_York", timeZoneName: "short" };

  const getBarColor = (recommendedAction) => {
    switch (recommendedAction) {
      case 'BUY':
        return '#40E0D0';  // Green
      case 'SELL':
        return '#DE3163';  // Red
      case 'HOLD':
        return '#808080';  // Grey
      default:
        return '#0000FF';  // Blue (default)
    }
  }

  const genAIdata = props.data.map(({ t, gen_ai_msg }) => {
    if (gen_ai_msg) {
      return {
        x: new Date(t).toLocaleString("en-US", opt),
        y: parseFloat(gen_ai_msg.Confidence).toFixed(2),
        label: {
          ts: new Date(t).toLocaleString("en-US", opt),
          ...gen_ai_msg,
        }
      };
    } else {
      //console.warn("gen_ai_msg is undefined for this data point.");
      return null;
    }
  }).filter(Boolean);

  const options = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        distributed: true,
      },
    },
    colors: genAIdata.map(item => getBarColor(item.label.RecommendedAction)),
    dataLabels: {
      enabled: false
    },
    xaxis: {
      type: 'category',
      labels: {
        show: false
      },
    },
    yaxis: {
      show: false
    },
    grid: {
      show: false
    },
    legend: {
      show: false
    },
    tooltip: {
      enabled: true,
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        const tooltipContent = ReactDOMServer.renderToString(<TooltipContent label={data.label} />);
        return tooltipContent;
      }
    },
  };

  const dataSeries = [{
    name: "Confidence",
    data: genAIdata
  }];

  return (
    <div>
      <ReactApexChart
        options={options}
        series={dataSeries}
        type="bar"
        height={100}
      />
    </div>
  );
}

export default memo(GenAIChart);
