import React, { useState } from "react";



function NavMenu({ items, isLoggedIn, onLogin, onLogout }) {
  const [activeIndex, setActiveIndex] = useState(null);

  function handleClick(index) {
    setActiveIndex(index === activeIndex ? null : index);
    //window.location.href = "/"
    console.log("selected: ", items[index].label)
    window.location.href = "/" + items[index].label

  }
  return (
    <nav>
      <ul>
        {items.map((item, index) => (
          <li
            key={item.label}
            className={index === activeIndex ? "active" : ""}
          >
            <a onClick={() => handleClick(index)}>{item.label}</a>
          </li>
        ))}
        {isLoggedIn ? (
          <li >
            <button onClick={onLogout} className="cta-button-secondary">Login</button>
          </li>
        ) : (
          <li>
            <button onClick={onLogin} className="cta-button-secondary">Logout</button>
          </li>
        )}
      </ul>
    </nav>
  );
}

export default NavMenu;
