import React from 'react';
import "./feedcard.css";

const FeedCard = ({ avatar, username, handle, time, content, children }) => {
  return (
    <div className="feed-card">
      <div className="avatar">
        <img className="avatar-image" src={avatar} alt="Avatar" />
      </div>
      <div className="feed-content">
        <div className="feed-header">
          <span className="username">{username}</span>
          <span className="handle">@{handle}</span>
          <span className="time">{time}</span>
        </div>
        <div className="feed-summary">{content}</div>
        <div className="chart-placeholder">
          {/* Placeholder for ApexCharts visualization */}
          <span>{children ? "": <div>Loading chart...</div>}</span>
          {children}
        </div>
      </div>
    </div>
  );
};

export default FeedCard;
