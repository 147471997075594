export const calculateFibonacciRetracement = (
  ohlc,
  period = 30,
  fibonacciLevels = [0.0, 0.236, 0.382, 0.5, 0.618, 0.786, 1.0]
) => {
  const lastPeriodData = ohlc.slice(-period);

  let max = -Infinity;
  let min = Infinity;
  lastPeriodData.forEach((dataPoint) => {
    const { high, low } = { high: dataPoint.y[1], low: dataPoint.y[2] };
    if (high > max) {
      max = high;
    }
    if (low < min) {
      min = low;
    }
  });

  const range = max - min;
  const levels = {};
  //console.log("FR-ohlc-close:", ohlc[ohlc.length-1].y[3], max, min, range);
  fibonacciLevels.forEach((level) => {
    const retracement = level * range;
    const levelValue = max - retracement;
    levels[level] = levelValue;
    //console.log(max, level, range, levelValue)
  });
  //console.log("levels:", levels)
  return levels;
};
