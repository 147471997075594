module.exports = {
    getUser: function() {
        const user = sessionStorage.getItem('user');
        if(user ==='undefined' || !user){
            return null;
        } else {
            return JSON.pasrse(user);
        }
    },

    getToken: function() {
        let token = sessionStorage.getItem('token')
        return token
    },

    setUserSession: function(user, token) {
        sessionStorage.setItem('user', JSON.stringify(user));
        sessionStorage.setItem('token', token);
    },

    resetUserSession: function() {
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('token');
    }

}