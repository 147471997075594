import { Container, Row, Col, Badge } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const AccountSummary = ({ data }) => {

  const tradeActivities = data;
  const dt = new Date( data[0].created_at_gmt).toLocaleDateString()

  console.log("Account activity for: ", dt);
  let PL = parseFloat(tradeActivities[tradeActivities.length - 1].pl);

  let long_count = 0;
  let short_count = 0;
  let p_long = 0;
  let p_short = 0;
  let l_long = 0;
  let l_short = 0;
  let tax = 0;

  tradeActivities.forEach((d) => {
    tax += parseFloat(d.tax);
    if (d["open_qty"] == 0) {
      let pl_delta = parseFloat(d["pl_delta"]);

      if (d["sell"]) {
        long_count += 1;
        if (pl_delta > 0) {
          p_long += pl_delta;
        }
        if (pl_delta < 0) {
          l_long += pl_delta;
        }
      }
      if (d["buy"]) {
        short_count += 1;
        if (pl_delta > 0) {
          p_short += pl_delta;
        }
        if (pl_delta < 0) {
          l_short += pl_delta;
        }
      }
    }
  });
  let total_trades = long_count + short_count;
  let total_profit = p_long + p_short;
  let total_loss = l_long + l_short;
  let net_pl = PL - tax;

  let options = {
    chart: {
      type: "bar",
      height: 250,
    },
    markers: {
      size: [1, 3],
      colors: ["#5b46f1"],
    },
    plotOptions: {
      bar: {
        colors: {
          ranges: [
            {
              from: 0,
              to: 999,
              color: "#68db0f",
            },
            {
              from: -999,
              to: 0,
              color: "#F15B46",
            },
          ],
        },
        columnWidth: "80%",
      },
    },
    stroke: {
      width: [0, 1],
      colors: ["#5b46f1"],
    },
    title: {
      text: "Intra day P& Loss (long/short)",
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    xaxis: {
      show: false,
      labels: {
        show: false
      },
    },

    yaxis: [
      {
        title: {
          text: "P&L (USD)",
        },
      },
      {
        opposite: true,
        title: {
          text: "Positions(qty)",
        },
      },
    ],

    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + "";
          }
          return y;
        },
      },
    },
  };

  let pfx11 = tradeActivities.map((data, i) => {
    var info = {
      x: i,
      y: data.open_qty,
    };
    return info;
  });

  let pfx21 = tradeActivities.map((data, i) => {
    var info = {
      x: i,
      y: isNaN(data.pl) ? 0 : data.pl,
    };
    return info;
  });

  const series = [
    {
      name: "P&L (Bar)",
      type: "column",
      data: pfx21,
    },
    {
      name: "Positions (liine)",
      type: "line",
      data: pfx11,
    },
  ];

  return (
    <div className="rounded mb-0">
  <Container style={{ padding: "1rem" }}>
    <h4>Intraday Summary ({dt})</h4>
    <br/>
    <Row>
      <Col xs={12} lg={6}>
        <ul>
          <h4 style={{ color: net_pl > 0 ? "blue" : "red" }}>
            Net Profit / Loss : {net_pl.toLocaleString("en-US", { style: "currency", currency: "USD" })}
            *
          </h4>
        </ul>
        <ul>
          <p>Trades : {total_trades} ( Long: {long_count} / Short: {short_count} ) </p>
        </ul>
        <ul>
          <p>
            Profit : {total_profit.toLocaleString("en-US", { style: "currency", currency: "USD" })}{" "}
            (Long: {p_long.toLocaleString("en-US", { style: "currency", currency: "USD" })} / Short:{" "}
            {p_short.toLocaleString("en-US", { style: "currency", currency: "USD" })} )
          </p>
        </ul>
        <ul>
          <p>
            Loss : {total_loss.toFixed(2).toLocaleString("en-US", { style: "currency", currency: "USD" })}{" "}
            (Long: {l_long.toLocaleString("en-US", { style: "currency", currency: "USD" })} / Short:{" "}
            {l_short.toLocaleString("en-US", { style: "currency", currency: "USD" })} )
          </p>
        </ul>
        <ul>
          <p>PL (excluding tax/fees) : {PL.toLocaleString("en-US", { style: "currency", currency: "USD" })}</p>
        </ul>
        <ul>
          <p>Taxes and Fees* : {tax.toLocaleString("en-US", { style: "currency", currency: "USD" })}</p>
        </ul>
        <ul>
          <p>* Approximate amount. Please refer to your Broker account for final amount</p>
        </ul>
      </Col>
      <Col xs={12} lg={6}>
        <div id="chart">
          <ReactApexChart options={options} series={series} type="bar" height={300} />
        </div>
      </Col>
    </Row>
  </Container>
</div>

  );
};

export default AccountSummary;
