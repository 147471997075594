function getTpOrders(item, limitOrders) {
  return limitOrders
    .filter(function (obj) {
      return obj.ts == item.ts;
    })
    .reverse();
}

function getFilledOrders(item, allOrders) {
  return allOrders.filter(function (obj) {
    return obj.status == "filled" && item.id == obj.id;
  });
}

function getPartFilledOrders(item, allOrders) {
  return allOrders.filter(function (obj) {
    return obj.status == "partially_filled" && item.id == obj.id;
  });
}

function getTpFilledOrders(item, allOrders) {
  return allOrders.filter(function (obj) {
    return (obj.status == "filled") && item.tp_id == obj.id;
  });
}
function getTpNewOrders(item, allOrders) {
  return allOrders.filter(function (obj) {
    return obj.status == "new" && item.tp_id == obj.id;
  });
}

function getCanceledOrders(item, allOrders) {
  return allOrders.filter(function (obj) {
    return obj.status == "canceled" && item.tp_id == obj.id;
  });
}

function computePL(item) {
  let pl = 0;
  if (item.side == "buy") {
    pl = (item.tp_filled_avg_price - item.limit_filled_avg_price) * item.qty;
  } else if (item.side == "sell_short") {
    pl = (item.limit_filled_avg_price - item.tp_filled_avg_price) * item.qty;
  }
  item.pl = pl;
}

function computeDuration(item) {
  let date1 = new Date(item.ts);
  let date2 = new Date(item.filled_at || item.canceled_at);
  const timeDiff = (date2.getTime() - date1.getTime()) / (1000 * 60);
  item.duration = timeDiff.toFixed(2);
}

function buildTradeDetails(item, limitOrders, allOrders) {
  //console.log(item.ts);
  const tpOrders = getTpOrders(item, limitOrders);
  if (tpOrders.length > 0) {
    item.tp = tpOrders[0].tp;
    item.tp_id = tpOrders[0].id;
  }

  const filled = getFilledOrders(item, allOrders);

  //console.log("limit orders:", filled)

  if (filled.length > 0) {
    item.limit_filled_avg_price = parseFloat(filled[0].filled_avg_price);
  }

  //console.log("tpOrders[0] :", tpOrders[0]);
  const tp_filled = getTpFilledOrders(item, allOrders);

  const part_filled = getPartFilledOrders(item, allOrders);
  //console.log("part: ", part_filled)
  const tp_new = getTpNewOrders(item, allOrders);

  const canceled = getCanceledOrders(item, allOrders);

  //console.log("filled orders", tp_filled);
  //console.log("canclled orders", canceled);

  if (tp_filled.length > 0) {
    item.status = tp_filled[0].status;
    item.filled_at = tp_filled[0].filled_at.substring(0, 19);
    item.tp_filled_avg_price = parseFloat(tp_filled[0].filled_avg_price);
    item.tp_limit = parseFloat(tp_filled[0].limit_price);
    item.target = "🎯"
    computeDuration(item);
    computePL(item);
  } else if (canceled.length > 0) {
    //console.log("canceled[0] :", canceled[0]);
    item.status = canceled[0].status;
    item.tp_limit = parseFloat(canceled[0].limit_price);
    item.canceled_at = canceled[0].canceled_at.substring(0, 19);
    item.target = "❌"
    computeDuration(item);
  } 
  /*else if (part_filled.length > 0) {
    item.status = part_filled[0].status;
    item.qty = part_filled[0].filled_qty;
    item.target = parseFloat(tp_new[0].limit_price);
  }*/
  else {
    item.status = "active";
    if (tp_new.length > 0){
      item.target = parseFloat(tp_new[0].limit_price);
    }
  }
}

const getTrades = (grp, limitOrders, allOrders, activities) => {
    // get trdaes for a group
    //console.log("-------------------------");

    const tradeGroup = activities
      .filter(function (obj) {
        return obj.group == grp;
      })
      .map(function (obj) {
        //console.log("trade group obj:", obj)
        return {
          ts: obj.created_at_gmt.substring(0, 19),
          limit: obj.limit,
          qty: obj.qty,
          side: obj.side,
          id: obj.id,
        };
      });
    // get the type of trade
    let tradeSide = tradeGroup[0].side;

    const trades = tradeGroup.filter(function (obj) {
      return obj.side == tradeSide;
    });

    if (tradeSide == "sell_short") {
      tradeSide = "buy";
    } else if (tradeSide == "buy") {
      tradeSide = "sell";
    }

    const filledTrades= []
    trades.forEach((item, index, arr) => {
      buildTradeDetails(item, limitOrders, allOrders);
      filledTrades.push(item)
    });
   
    //console.log("Filled ggrades:", filledTrades)
    return filledTrades
  };

  export default getTrades;