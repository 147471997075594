import React, { useEffect, useState, memo } from "react";
import ReactApexChart from "react-apexcharts";
import { calculateFibonacciRetracement } from "../service/utils";
import getDataAPI from "../service/apiservice";
import TooltipContent from "./GenAIToolTip";
import { Button } from "react-bootstrap";

function TradeChart(props) {
  const opt = { timeZone: "America/New_York", timeZoneName: "short" };
  const [chartToDisplay, setChartToDisplay] = useState([]);
  //console.log("Tradechart OHLC:", props.data)

  const ohlc = props.data.map(({ t, open, high, low, close }) => ({
    x: new Date(t).toLocaleString("en-US", opt),
    y: [parseFloat(open), parseFloat(high), parseFloat(low), parseFloat(close)],
  }));

  //console.log("Tradechart OHLC:", ohlc)

  const [retracements, setRetracements] = useState([]);

  const handleOrder = (order_type, entry_price, stop_loss, take_profit) => {
    console.log("Stop Loss:", stop_loss);
    console.log("Take Profit:", take_profit);
    let msg = {
      order_type: order_type,
      lp: entry_price.toFixed(2),
      tp: take_profit.toFixed(2),
      sl: stop_loss.toFixed(2),
    };

    let resp = getDataAPI(msg, "trade/order");
    resp.then((output) => {
      console.log("message:", output);
      alert(output);
    });
  };

  useEffect(() => {
    const period = 30;
    if (ohlc.length > period) {
      const retracementLevels = calculateFibonacciRetracement(ohlc, period);

      const newRetracements = Object.values(retracementLevels);

      setRetracements(newRetracements);
    }
  }, [props.data]);

  useEffect(() => {
    const grid = {
      show: false,
    };

    const modifiedAnnotations = {
      position: "back",
      yaxis: retracements.map((level) => {
        //console.log("adding retracement:", level);
        return {
          y: level,
          borderColor: "#ccc",
          borderWidth: 1,
          label: {
            text: `${((level / ohlc[ohlc.length - 1].y[3]) * 100).toFixed(2)}%`,
            style: {
              background: "lightgrey",
              color: "#fff",
              opacity: ".3",
            },
            position: "left",
          },
        };
      }),
    };
    const charts = [];
  }, [props.data, retracements]);

  const min = Math.min(...ohlc.map((d) => d.y[2]));
  const max = Math.max(...ohlc.map((d) => d.y[1]));

  const rsi = props.data.map(({ t, rsi }) => ({
    x: new Date(t).toLocaleString("en-US", opt),
    y: parseFloat(rsi),
  }));

  const up = props.data.map(({ t, up }) => ({
    x: new Date(t).toLocaleString("en-US", opt),
    y: parseFloat(up).toFixed(2),
  }));

  //console.log("up:", up)

  const hold = props.data.map(({ t, hold }) => ({
    x: new Date(t).toLocaleString("en-US", opt),
    y: parseFloat(hold).toFixed(2),
    label: {
      text: "Hold Value", // Replace "Hold Value" with your desired text
      style: {
        color: "#007bff", // Example: Set the label text color to blue
      },
    },
  }));

  const down = props.data.map(({ t, down }) => ({
    x: new Date(t).toLocaleString("en-US", opt),
    y: parseFloat(down).toFixed(2),
  }));

  const pv = props.data.map(({ t, pv }) => ({
    x: new Date(t).toLocaleString("en-US", opt),
    y: parseFloat(pv).toFixed(2),
  }));

  const news = props.data.map(({ t, news }) => ({
    x: new Date(t).toLocaleString("en-US", opt),
    y: news,
  }));

  let latestNews = null;
  //console.log("news:", news)
  if (Array.isArray(news) && news.length > 0) {
    for (let i = news.length - 1; i >= 0; i--) {
      if (news[i] && news[i].y) {
        try {
          const parsedNews = JSON.parse(news[i].y);
          if (parsedNews && parsedNews.headline) {
            latestNews = {
              x: news[i].x,
              y: parsedNews
            };
            break;
          }
        } catch (error) {
          console.error("Error parsing news:", error);
        }
      }
    }
  }
  
  if (!latestNews) {
    latestNews = { x: "No date", y: { headline: "No news available" } };
  }
  //console.log("latest news:", latestNews)

  const genAI = props.data.slice(-2).map((row, index) => {
    // Check if the current row has gen_ai_msg
    if (row?.gen_ai_msg) {
      return {
        x: new Date(row.t).toLocaleString("en-US", opt),
        y: row.gen_ai_msg.Confidence,
        label: row.gen_ai_msg,
      };
    } else {
      // If gen_ai_msg is missing in the current row, check the previous row (index-1)
      const previousRow = props.data[index - 1];
      if (previousRow?.gen_ai_msg) {
        return {
          x: new Date(row.t).toLocaleString("en-US", opt), // Use current row's timestamp
          y: previousRow.gen_ai_msg.Confidence,
          label: previousRow.gen_ai_msg,
        };
      } else {
        // Handle the case where gen_ai_msg is missing in both rows
        console.warn(
          "gen_ai_msg is undefined for both current and previous data points."
        );
        return {
          x: new Date(row.t).toLocaleString("en-US", opt),
          // Set y and label to appropriate defaults or leave them undefined
          y: undefined,
          label: undefined,
        };
      }
    }
  });

  const label = genAI?.[0]?.label || {};
  //console.log("LABEL:", label.length)
  const gen_ai_msg_ts = genAI?.[0]?.x || "None";

  //console.log("GEN AI last row:", label);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "scatter",
      height: 300,
      zoom: {
        enabled: true,
        type: "x",
      },
      animations: {
        enabled: false,
      },
    },
    grid: {
      show: false,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },

    plotOptions: {
      candlestick: {
        colors: {
          upward: "#1FCEFF47",
          downward: "#FFCC0047",
        },
      },
      scatter: {
        colors: ["#1fad45", "#c71423", "#1468c7"],
      },

      markers: {
        size: [0, 2],
        colors: ["#1fad45", "#c71423", "#1468c7"],
        shape: "square",
      },

      events: {
        zoomed: function (chartContext, { xaxis, yaxis }) {
          //console.log("zoom", yaxis);
          setChartOptions({
            ...chartOptions,
            yaxis: {
              min: yaxis[0].min,
              max: yaxis[0].max,
              lines: {
                show: false,
              },
            },
          });
        },
      },
    },
    title: {
      //text: "CandleStick Chart",
      align: "left",
    },
    grid: {
      show: true,
    },
    xaxis: {
      type: "datetime",
      scrollbar: {
        enabled: true,
        //window: 30, // default window size in days
        range: "last30",
      },
      labels: {
        formatter: function (val) {
          return new Date(val).toLocaleDateString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          });
        },
      },
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
      min: Math.min(...ohlc.map((d) => d.y[2])),
      max: Math.max(...ohlc.map((d) => d.y[1])),
    },
    labels: {
      formatter: (value) => {
        return value.foFixed(2);
      },
    },
  });

  const ohlcSeries = {
    series: [
      { name: "candlestick", type: "candlestick", data: ohlc },
      { name: "UP Trend", type: "scatter", data: up },
      //{ name: "hold", type: "scatter", data: hold },
      { name: "Predicted Target", type: "scatter", data: pv },
      { name: "DOWN Trend", type: "scatter", data: down },
    ],
  };

  const ohlcSeries1 = {
    series: [
      //{ name: "candlestick", type: "candlestick", data: ohlc },
      { name: "scatter", type: "scatter", data: hold },
    ],
  };
  //console.log("series1", ohlcSeries);

  useEffect(() => {
    setChartOptions({
      ...chartOptions,
      yaxis: {
        tooltip: {
          enabled: true,
        },
        min: Math.min(...ohlc.map((d) => d.y[2])),
        max: Math.max(...ohlc.map((d) => d.y[1])),
      },
    });
  }, [props.data]);

  return (
    <div
      className="rounded mb-0"
      style={{
        height: "650px",
      }}
    >
      {" "}
      <div
        style={{
          whiteSpace: "nowrap",
          overflowX: "auto",
          textOverflow: "ellipsis",
          maxWidth: "1200px" /* Adjust max width as needed */,
          fontSize: "0.8rem" /* Adjust font size for subtlety */,
          color: "#808080" /* Adjust color for a subtle tone */,
        }}
      >
        Latest Headline: {latestNews?.y?.headline || "No news"}
      </div>
      <div
        style={{
          backgroundColor: "#fff",
          border: "1px solid #e6ecf0",
          borderRadius: "15px",
          padding: "12px",
          fontFamily: "Arial, sans-serif",
          maxWidth: "1200px",
          margin: "0 auto",
          display: "flex",
        }}
      >
        <div
          style={{
            flex: "0 0 50%",
            padding: "8px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <div
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                color: "#4CAF50",
                marginRight: "4px",
              }}
            >
              Recommended Action: {label?.RecommendedAction || "None"} [
              Confidence: {label?.Confidence ? label.Confidence + "%" : "NA"} ]
            </div>
          </div>
          <div
            style={{
              fontSize: "14px",
              color: "#14171a",
              marginBottom: "8px",
            }}
          >
            Prediction Model: {label?.BaseModelAction || false}
            {label.Assessment === true ? ( // Use strict comparison for booleans
              <span style={{ color: "green" }}>👍</span>
            ) : (
              <span style={{ color: "red" }}>👎</span>
            )}
          </div>

          <div style={{ marginBottom: "8px", fontSize: "14px" }}>
            Patterns: {label?.patterns || "No Patterns"}
          </div>
          
          <div style={{ marginBottom: "8px", fontSize: "14px" }}>
            News Sentiment: {label?.NewsSentiment || "None"} (
             Impact: {label?.NewsImpactScore || "None"})
          </div>

          {(label?.stop_loss !== null || label?.take_profit !== null) && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "14px",
                color: "#657786",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "8px",
                }}
              >
                Time: {gen_ai_msg_ts}
                {label?.entry_price !== null && (
                  <div style={{ marginRight: "8px" }}>
                    LImit Price: {label.entry_price}
                  </div>
                )}
                {label?.take_profit !== null && (
                  <div style={{ marginRight: "8px" }}>
                    Take Profit: {label.take_profit}
                  </div>
                )}
                {label?.stop_loss !== null && (
                  <div style={{ marginRight: "8px" }}>
                    Stop Loss: {label.stop_loss}
                  </div>
                )}
              </div>
            </div>
          )}
          <div style={{ flexGrow: 1 }}></div>
          {(label?.stop_loss !== null || label?.take_profit !== null) &&
            label?.entry_price !== null &&
            label.RecommendedAction !== "HOLD" && (
              <div style={{ marginTop: "8px" }}>
                <Button
                  variant="success"
                  disabled={!(label?.stop_loss && label?.take_profit)} // Combined check for required fields
                  onClick={() =>
                    handleOrder(
                      label.RecommendedAction,
                      label.entry_price,
                      label.stop_loss,
                      label.take_profit
                    )
                  }
                >
                  Execute {label.RecommendedAction} Order
                </Button>
              </div>
            )}
        </div>

        <div
          style={{
            flex: "0 0 50%",
            padding: "8px",
            borderLeft: "1px solid #e6ecf0",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              color: "#657786",
              marginTop: "4px",
              lineHeight: "1.4",
              wordWrap: "break-word",
              overflowWrap: "break-word",
              hyphens: "auto",
              whiteSpace: "pre-wrap",
              maxHeight: "200px",
              overflowY: "auto",
              backgroundImage: label.Reason
                ? "none"
                : "url('https://miro.medium.com/v2/resize:fit:872/1*oy1_SOkKGIhP0PAoWolNTw.gif')",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          >
            {label.Reason}
          </div>
        </div>
      </div>
      <br />
      <div id="chart-candlestick">
        <ReactApexChart
          options={chartOptions}
          series={ohlcSeries.series}
          type="scatter"
          height={400}
        />
      </div>
    </div>
  );
}

export default memo(TradeChart);
