import { useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "../prediction.css";
import { ButtonGroup, Button, Row, Col, Container } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";

const dateToString = (d) => `${d.getHours()}:${d.getMinutes()}`;

const options = {
  chart: {
    height: 350,
    type: "line",
  },
  stroke: {
    curve: "smooth",
    width: [3],
  },
  fill: {
    type: "solid",
    opacity: [0.35, 1],
  },
  dataLabels: {
    enabled: false,
  },
};

const PredictionChart = ({ data, callbackFromParent }) => {
  const [window, setWindow] = useState(80);
  const [scale, setScale] = useState(5);

  const handleSliderChange = (newValue) => {
    setWindow(newValue);

    callbackFromParent(newValue, scale);
  };
  const handleScaleChange = (newValue) => {
    setScale(newValue);
    callbackFromParent(window, newValue);
  };
  const px11 = data.map((data, i) => ({
    x: dateToString(new Date(data.date)),
    y: isNaN(data.correct_up_pct) ? 0 : data.correct_up_pct,
  }));
  const px21 = data.map((data, i) => ({
    x: dateToString(new Date(data.date)),
    y: isNaN(data.correct_down_pct) ? 0 : data.correct_down_pct,
  }));
  const px31 = data.map((data, i) => ({
    x: dateToString(new Date(data.date)),
    y: isNaN(data.correct_pct) ? 0 : data.correct_pct,
  }));

  var positiveData1 = data.filter(function (obj) {
    return !isNaN(obj.correct_up_pct) && obj.correct_up_pct > 0;
  });
  const x1_average =
    positiveData1.reduce(function (sum, obj) {
      return sum + parseFloat(obj.correct_up_pct);
    }, 0) / positiveData1.length;

  var positiveData2 = data.filter(function (obj) {
    return !isNaN(obj.correct_down_pct) && obj.correct_down_pct > 0;
  });
  const x2_average =
    positiveData2.reduce(function (sum, obj) {
      return sum + parseFloat(obj.correct_down_pct);
    }, 0) / positiveData2.length;

  var positiveData3 = data.filter(function (obj) {
    return !isNaN(obj.correct_pct) && obj.correct_pct > 0;
  });
  const x3_average =
    positiveData3.reduce(function (sum, obj) {
      return sum + parseFloat(obj.correct_pct);
    }, 0) / positiveData3.length;

  let series = [
    {
      name: "Overall",
      type: "area",
      data: px31,
      color: "#00C3FF89",
    },
    {
      name: "Long",
      type: "line",
      data: px11,
      color: "#0022FFFF",
    },
    {
      name: "Short",
      type: "line",
      data: px21,
      color: "#FF0000FF",
    },
  ];

  return (
    <div
      className="rounded mb-0"
      style={{
        height: "",
      }}
    >
      <br />
      <p>Prediction Accuracy</p>
      <br />
      <Container>
        <Row>
          <Col xs={12} lg={6}>
            <p>
              Long : {x1_average.toFixed(2)}% | Short : {x2_average.toFixed(2)}% | 
              Overall : {x3_average.toFixed(2)}%
            </p>
          </Col>
          <Col xs lg="3">
            <div>
              <p>Prediction Window: {window} Minutes</p>
              <Slider
                value={window}
                min={10}
                max={200}
                onChange={handleSliderChange}
              />
            </div>
          </Col>
          <Col xs lg="3">
            <div>
              <p>Prediction Scale: {scale}</p>
              <Slider
                value={scale}
                min={1}
                max={10}
                onChange={handleScaleChange}
              />
            </div>
          </Col>
        </Row>
        <div>
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height={350}
          />
        </div>
      </Container>
      <br />
    </div>
  );
};

export default PredictionChart;
