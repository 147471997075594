
const prepareOrder= function(jsonData) {
        var positions = {};
        var entity = jsonData["entity"];
        var event = jsonData["event"];
        var order = jsonData["order"];
        var position_qty = jsonData["position_qty"];
        var pressure_BOD = jsonData["pressure_BOD"];
        var pressure_window = jsonData["pressure_window"];
        var c_delta = jsonData["c_delta"];
    
        let activity = null;
    
        if (entity === "order") {
          /*
          if (order.order_class == "bracket") {
            console.log("bracket:", order);
          }*/
          switch (event) {
            case "held":
              break
            case "new":
              break;
            //case 'partial_fill':
            case "canceled":
            case "fill":
              let q = parseInt(order.filled_qty);
              if (event === "canceled" && q === 0) {
                break;
              }
              {
                //positions
                positions.qty = position_qty;
                positions.filled_avg_price = order.filled_avg_price;
                positions.side = order.side;
                //activities
                let side =
                  order.side === "sell" && position_qty < 0
                    ? "sell_short"
                    : order.side;
                activity = {
                  activity_id: order.filled_at + order.id,
                  symbol:order.symbol,
                  id: order.id,
                  activity_type: "FILL",
                  transaction_time: order.filled_at,
                  submitted_at_gmt: order.submitted_at,
                  submitted_at:
                    order.submitted_at > ""
                      ? new Date(order.submitted_at).toLocaleString("en-US")
                      : "",
                  created_at:
                    order.created_at > ""
                      ? new Date(order.created_at).toLocaleString("en-US")
                      : "",
                  created_at_gmt: order.created_at,
                  type: order.type,
                  price: order.filled_avg_price,
                  limit:
                    order.stop_price > "" ? order.stop_price : order.limit_price,
                  qty: parseInt(order.filled_qty),
                  side: side,
                  symbol: order.symbol,
                  leaves_qty: "0",
                  order_id: order.id,
                  cum_qty: parseInt(order.qty),
                  order_status: "filled",
                  pressure_BOD: pressure_BOD,
                  pressure_window: pressure_window,
                  c_delta: c_delta,
                };
                break;
              }
            case "canceled1":
              break;
            default:
              break;
              //console.log(`unknown event ${event}.`);
          }
        }
        return [activity, positions];
      }
    
export default prepareOrder